export interface IPrivacyData {
    service: boolean;
    privacyTitle: string;
    privacySubTitle: string;
    body: string;
};

export class PrivacyAdapter {
    constructor(
        public service: boolean,
        public privacyTitle: string,
        public privacySubTitle: string,
        public body: string,
    ) {};
  
    public static createPrivacyData(data: any): any {
        return new PrivacyAdapter(
            data.service,
            data?.['privacy.title']?.value || '',
            data?.['privacy.subTitle']?.value || '',
            data?.['privacy.body']?.value || '',
        );
    };
};