import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useRouter } from 'next/router';

interface RouteContextType {
  visitedRoutes: Array<string>;
};

const RouteContext = createContext<RouteContextType | undefined>(undefined);

export function useRouteContext(): RouteContextType {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error('useRouteContext must be used within a RouteProvider');
  }
  return context;
}

interface RouteProviderProps {
  children: ReactNode;
}

export function RouteProvider({ children }: RouteProviderProps): JSX.Element {
  const router = useRouter();
  const [visitedRoutes, setVisitedRoutes] = useState<Array<string>>([]);

  useEffect(() => {
    setVisitedRoutes([...visitedRoutes, router.asPath]);
  }, [router.asPath]);

  return (
    <RouteContext.Provider value={{ visitedRoutes }}>
      {children}
    </RouteContext.Provider>
  );
}