import { TermsAdapter } from "@/models/adapter/termsAdapter";
import { GlobalDatasProps } from "..";

export const setTermsData = (state: GlobalDatasProps, action: {
    type: 'SET_TERMS_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = TermsAdapter.createTermsData(payload.data);
    return {
        ...state,
        terms: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setTermsError = (state: GlobalDatasProps, action: {
    type: 'SET_TERMS_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        terms: {
            ...state.terms,
            error
        }
    };
};