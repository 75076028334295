import { CitysAdapter } from "@/models/adapter/citysAdapter";
import { GlobalDatasProps } from "..";

export const setCitysData = (state: GlobalDatasProps, action: {
    type: 'SET_CITYS_DATA';
    payload: any | null;
}) => {
    const { payload: { data } } = action;
    const adaptedData = Array.isArray(data?.posts) ? data?.posts.map(CitysAdapter.createCitysData) : [];

    return {
        ...state,
        citys: {
            data: adaptedData
        }
    };
};

export const setCitysError = (state: GlobalDatasProps, action: {
    type: 'SET_CITYS_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;

    return {
        ...state
    };
};

export const setCitysLoading = (state: GlobalDatasProps, action: {
    type: 'SET_CITYS_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state
    };
};