
import { createContext } from "react";
import { useState } from "react";

interface ThankYouContextProps {
    isEntry: boolean;
    openEntryThankYou: () => void,
    closeEntryThankYou: () => void,
};

const ThankYouContext = createContext<ThankYouContextProps>({
    isEntry: false,
    openEntryThankYou: () => {},
    closeEntryThankYou: () => {},
});

interface ThankYouProviderProps {
  children: React.ReactNode;
};

const ThankYouProvider: React.FC<ThankYouProviderProps> = ({ children }) => {
  const [isEntry, setIsEntry] = useState(false);

  const openEntryThankYou = () => setIsEntry(() => true);
  const closeEntryThankYou = () => setIsEntry(() => false);
  
  return (
    <ThankYouContext.Provider value={{ isEntry, openEntryThankYou, closeEntryThankYou }}>
      {children}
    </ThankYouContext.Provider>
  );
};

export { ThankYouContext, ThankYouProvider };