import { GlobalDatasProps } from "..";
import { ReviewsAdapter } from "@/models/adapter/reviewsAdapter";

export const setReviewsData = (state: GlobalDatasProps, action: {
    type: 'SET_REVIEWS_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = ReviewsAdapter.createReviewsData(payload.data);

    return {
        ...state,
        reviews: {
            data,
            error: !payload.action ? true : null,
            isLoading: false
        },
    };
};

export const setReviewsError = (state: GlobalDatasProps, action: {
    type: 'SET_REVIEWS_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        reviews: {
            ...state.reviews,
            error
        }
    };
};