import { GlobalDatasProps } from "..";
import { TransportServicesAdapte } from "@/models/adapter/transportServicesAdapter";

export const setTransportServiceData = (state: GlobalDatasProps, action: {
    type: 'SET_TRANSPORT_SERVICES_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = TransportServicesAdapte.transportServicesAdapteData(payload.data);
    return {
        ...state,
        transportServices: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};