
import useWindowSize from "@/hooks/useWindowSize";
import { createContext, useEffect } from "react";
import { useState } from "react";

interface MobileFormsPopupContextProps {
  isOpen: boolean;
  openMobileFormsPopup: () => void;
  closeMobileFormsPopup: () => void;
  toogleMobileFormsPopup: () => void;
};

const MobileFormsPopupContext = createContext<MobileFormsPopupContextProps>({
  isOpen: false,
  openMobileFormsPopup: () => {},
  closeMobileFormsPopup: () => {},
  toogleMobileFormsPopup: () => {}
});

interface MobileFormsPopupProviderProps {
  children: React.ReactNode;
};

const MobileFormsPopupProvider: React.FC<MobileFormsPopupProviderProps> = ({ children }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.style.overflow = isOpen ? 'hidden' : 'initial';
    };
  }, [isOpen]);

  const openMobileFormsPopup = () => setIsOpen(true);
  const closeMobileFormsPopup = () => setIsOpen(false);
  const toogleMobileFormsPopup = () => setIsOpen(!isOpen);

  return (
    <MobileFormsPopupContext.Provider value={{
      isOpen: Number(width) <= 768 && isOpen,
      openMobileFormsPopup,
      closeMobileFormsPopup,
      toogleMobileFormsPopup
    }}>
      {children}
    </MobileFormsPopupContext.Provider>
  );
};

export { MobileFormsPopupContext, MobileFormsPopupProvider };