export const ActiveStareIcon: React.FC = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.9996 26.8083L8.24403 31.7004C7.90141 31.9287 7.54322 32.0265 7.16946 31.9939C6.79569 31.9613 6.46865 31.8308 6.18833 31.6025C5.908 31.3742 5.68997 31.0892 5.53424 30.7474C5.3785 30.4043 5.34736 30.0208 5.4408 29.5968L7.4965 20.3509L0.628578 14.138C0.317108 13.8445 0.12275 13.5098 0.0455053 13.1341C-0.0329853 12.7597 -0.00993656 12.3931 0.114652 12.0344C0.23924 11.6756 0.426122 11.3821 0.675298 11.1538C0.924475 10.9255 1.26709 10.7788 1.70315 10.7135L10.7669 9.8819L14.271 1.17409C14.4267 0.782726 14.6684 0.489203 14.9961 0.293522C15.3225 0.0978408 15.657 0 15.9996 0C16.3423 0 16.6774 0.0978408 17.0051 0.293522C17.3315 0.489203 17.5726 0.782726 17.7283 1.17409L21.2324 9.8819L30.2961 10.7135C30.7322 10.7788 31.0748 10.9255 31.324 11.1538C31.5732 11.3821 31.7601 11.6756 31.8846 12.0344C32.0092 12.3931 32.0329 12.7597 31.9557 13.1341C31.8772 13.5098 31.6822 13.8445 31.3707 14.138L24.5028 20.3509L26.5585 29.5968C26.6519 30.0208 26.6208 30.4043 26.4651 30.7474C26.3093 31.0892 26.0913 31.3742 25.811 31.6025C25.5306 31.8308 25.2036 31.9613 24.8298 31.9939C24.4561 32.0265 24.0979 31.9287 23.7553 31.7004L15.9996 26.8083Z"
            fill="#FFDA22"
        />
    </svg>
);