import { useContext, useEffect, useState } from 'react';
import { ActiveStareIcon } from '@/public/assets/svges/ActiveStareIcon';
import { Container } from '@/components/ui/container';
import { CloseIconReviewPopup } from '@/public/assets/svges/CloseIconReviewPopup';
import { DislikeIcon } from '@/public/assets/svges/DislikeIcon';
import { LikeIconReview } from '@/public/assets/svges/LikeIconReview';
import { DisableStarIcon } from '@/public/assets/svges/DisableStarIcon';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReviewsFormContext } from '@/context/reviewsForm';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { addFeedbackCall, getOptions, getReviews } from '@/constants/service';
import { GlobalDatasContext } from '@/context/globalDatas';
import { setReviewsDataAction } from '@/context/globalDatas/reducer/actions/reviews';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { handleTypeChangeName, handleTypeChangeYear, hendleTypeRemoveSpace } from '@/helper/strings';
import * as yup from "yup";
import Recaptcha from "react-google-recaptcha";
import Image from 'next/image';
import classNames from 'classnames';
import classes from './index.module.css';

type NamesType = 'name' | 'email' | 'year' | 'make' | 'model';

interface IFormData {
    name: string,
    email: string,
    year: string,
    make: string,
    model: string,
    pick_up: string,
    drop_off: string,
    tell_us: string,
};

interface IFeedbackPayload extends IFormData {
    like_dislike: number;
    star: number;
};

const defaultValues = {
    name: '',
    email: '',
    year: '',
    make: '',
    model: '',
    pick_up: '',
    drop_off: '',
    tell_us: ''
};

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required().email(),
    year: yup.number()
        .typeError('Year must be a number')
        .integer('Year must be an integer')
        .min(2000, 'must be least 2000')
        .max(new Date().getFullYear(), 'Year cannot exceed the current year')
        .required('Year is required'),
    make: yup.string().required('Make is required'),
    model: yup.string().required('Model is required'),
    pick_up: yup.string().required(),
    drop_off: yup.string().required(),
    tell_us: yup.string().required()
});

const FeedbackForm: React.FC = () => {
    const [ like, setLike ] = useState<boolean | null>(null);
    const { closeReviewsForm, isOpen } = useContext(ReviewsFormContext);
    const { dispatch } = useContext(GlobalDatasContext);

    const [ ratingCount, setRatingCount ] = useState<0 | 1 | 2 | 3 | 4 | 5 | any>(0);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors }
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { pick_up, drop_off } = watch();

    const { mutate, isLoading, isSuccess }: UseMutationResult<
        any, unknown, IFeedbackPayload, unknown
    > = useMutation('feedback', addFeedbackCall, {
        onSuccess: () => {
            closeForm();
            toast.success('your feedback have successfully', {
                position: toast.POSITION.TOP_RIGHT
            });
        },
        onError: () => {
            toast.error('sorry something is wrong', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    });

    const { data: optionsPick_up, isLoading: loadingPick_up } = useQuery(['optionsForm', pick_up], getOptions(pick_up), {
        enabled: Boolean(pick_up)
    });

    const { data: optionsDrop_off, isLoading: loadingDrop_off } = useQuery(['optionsTo', drop_off], getOptions(drop_off), {
        enabled: Boolean(drop_off)
    });

    useQuery('reviews', getReviews(), {
        onSuccess: data => dispatch(setReviewsDataAction(data)),
        enabled: isSuccess
    });

    useEffect(() => {
        if(!isOpen) {
            setLike(null);
            setRatingCount(0);
        };
    }, [isOpen]);

    function closeForm() {
        reset();
        setLike(null);
        setRatingCount(0);
        closeReviewsForm();
    };

    const onSubmit = (data: IFormData) => {
        mutate(Object.assign(data, {
            like_dislike: like ? 1 : 0,
            star: ratingCount
        }));
    };

    const getPropsTextFaild = (name: NamesType, label: string): TextFieldProps => ({
        className: classes.input,
        error: Boolean(errors?.[name]?.message),
        helperText: errors?.[name]?.message,
        variant: 'standard',
        InputLabelProps: { style: { color: '#A1A1A2' } },
        label,
        ...register(name)
    });

    if(!isOpen) return null;

    return (
        <div className={classNames(classes.feedbackForm, 'feedbackForm')}>
            <Container>
                <div className={classes.content}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.fromContent}>
                            <h2 className={classes.fomrTitle}>Submit a Review</h2>
                            <div className={classes.wrapperInput}>
                                <TextField
                                    {...getPropsTextFaild('name', 'Name *')}
                                    onChange={(event) => hendleTypeRemoveSpace({event})}
                                />
                            </div>
                            <div className={classes.wrapperInput}>
                                <TextField
                                    {...getPropsTextFaild('email', 'Email Address *')}
                                    onChange={(event) => hendleTypeRemoveSpace({event})}
                                />
                            </div>
                            <div className={classes.vihacleList}>
                                <div className={classes.wrapperInput}>
                                    <TextField
                                        {...getPropsTextFaild('year', 'Year *')}
                                        onChange={(event) => handleTypeChangeYear({event})}
                                    />
                                </div>
                                <div className={classes.wrapperInput}>
                                    <TextField
                                        {...getPropsTextFaild('make', 'Make *')}
                                        onChange={(event) => hendleTypeRemoveSpace({event})}
                                    />
                                </div>
                                <div className={classes.wrapperInput}>
                                    <TextField
                                        {...getPropsTextFaild('model', 'Model *')}
                                        onChange={(event) => hendleTypeRemoveSpace({event})}
                                    />
                                </div>
                            </div>
                            <div className={classes.locations}>
                                <div className={classes.wrapperInput}>
                                    <Autocomplete
                                        className={'autocomplete'}
                                        options={optionsPick_up?.data || []}
                                        clearOnBlur={false}
                                        loading={loadingPick_up}
                                        value={pick_up}
                                        renderInput={(params) => <TextField
                                            variant='standard'
                                            onSelect={(event) => {
                                                const { value } = event.target as any;
                                                setValue('pick_up', value);
                                            }}
                                            error={Boolean(errors?.pick_up?.message)}
                                            helperText={errors?.pick_up?.message}
                                            {...params}
                                            {...register('pick_up')}
                                            placeholder="Pick-Up Location"
                                        />}
                                    />
                                </div>
                                <div className={classes.wrapperInput}>
                                    <Autocomplete
                                        className={'autocomplete'}
                                        options={optionsDrop_off?.data || []}
                                        clearOnBlur={false}
                                        loading={loadingDrop_off}
                                        value={drop_off}
                                        renderInput={(params) => <TextField
                                            variant='standard'
                                            onSelect={(event) => {
                                                const { value } = event.target as any;
                                                setValue('drop_off', value);
                                            }}
                                            error={Boolean(errors?.drop_off?.message)}
                                            helperText={errors?.drop_off?.message}
                                            {...params}
                                            {...register('drop_off')}
                                            placeholder="Drop-Off Location"
                                        />}
                                    />
                                </div>
                            </div>

                            <TextField
                                {...register('tell_us')}
                                onChange={(event) => hendleTypeRemoveSpace({event})}
                                className={classes.textArea}
                                error={Boolean(errors.tell_us?.message)}
                                id="outlined-multiline-static"
                                helperText={errors.tell_us?.message}
                                label="Your Message*"
                                multiline
                                rows={4}
                                inputProps={{
                                    style: { height: '100%', width: '100%' }
                                }}
                            />

                            <div className={classes.recommend}>
                                <p>Do you recommend our services to a friend?</p>
                                <i onClick={() => setLike(true)}>
                                    <LikeIconReview
                                        {...(like !== null && { color: like ? '#00A800' : '#99BAC9' })}
                                    />
                                </i>
                                <i onClick={() => setLike(false)}>
                                    <DislikeIcon
                                        {...(like !== null && { color: like === true ? '#99BAC9' : '#FF0000' })}
                                    />
                                </i>
                            </div>

                            <div className={classes.rating}>
                                {[...new Array(5)].map((_, index) => (
                                    <i
                                        key={index}
                                        onClick={() => setRatingCount(index + 1)}
                                    >
                                        { ratingCount <= index ? <DisableStarIcon  /> : <ActiveStareIcon />}
                                    </i>
                                ))} 
                            </div>

                            <div className={classes.recapcha}>
                                <Recaptcha
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'asdsad54sad4'}
                                    onChange={(token) => {
                                        console.log("reCAPTCHA token:", token);
                                    }}
                                />
                            </div>

                            <button className={classes.btn} type='submit'>
                                {isLoading ? <LoadingUI type='roundSmall'/> : 'Submit'}
                            </button>
                        </div>
                    </form>
                    <div className={classes.imageNode}>
                        <Image
                            src={'/assets/images/reviewFormImage.png'}
                            alt={'Image'}
                            className={classes.image}
                            width={570}
                            height={670}
                        />
                    </div>
                    <div className={classes.closeForm} onClick={closeReviewsForm}>
                        <CloseIconReviewPopup />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export { FeedbackForm };