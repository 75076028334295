import React, { useContext, useState } from "react";
import { GlobalDatasContext } from "@/context/globalDatas";
import { Container } from "@/components/ui/container";
import { Search } from "./Search";
import { useRouter } from "next/router";
import { LINKS_FROM_MENU_TITLES } from "@/constants/words";
import Dropdown from "@/components/ui/Dropdown";
import Image from "next/image";
import Link from "next/link";
import classNames from "classnames";
import classes from './index.module.css';

interface IProps {
    color?: 'white';
    bg?: boolean;
    colorBlue?: boolean;
}

const InformationBar: React.FC<IProps> = ({ bg, color, colorBlue }) => {
    const { menus: { data, error, isLoading } } = useContext(GlobalDatasContext);
    const { query, pathname } = useRouter();
    const [ imageError, setImageError ] = useState(false);

    const unexpectedItems = [
        LINKS_FROM_MENU_TITLES.termsAndConditions,
        LINKS_FROM_MENU_TITLES.privacyPolicy,
        LINKS_FROM_MENU_TITLES.quickLinks
    ];
    
    const isWithe = Boolean(query.dynamicPage || pathname === '/news' || pathname === '/blogs') === false
    
    const handleImageError = () => setImageError(true);
  
    if(error !== null || isLoading === true || data === null) return null;

    return (
        <section style={bg ? { background: '#F4F4F3' } : {}}>
            <Container>
                <div className={classes.informationBar}>
                    <div className={classes.logo}>
                        <Link href='/'>
                            <Image
                                src={!imageError && data.logo || "/assets/images/logo.png"}
                                alt="hero background image"
                                width={130}
                                height={95}
                                onError={handleImageError}
                                priority
                            />
                        </Link>
                    </div>
                    <div className={classes.menuSelects}>
                        { data.menuItems.map(({ title, children, id, url }) => children.length ? !unexpectedItems.includes(title) && (
                            <Dropdown
                                key={id}
                                label={title}
                                items={children.map(({ url, title }) => ({
                                    link: url,
                                    label: title
                                }))}
                                colorWhite={Boolean((isWithe || color) && colorBlue !== true)}
                            />
                        ) : !unexpectedItems.includes(title) && (
                            <Link
                                className={classNames({[classes.colorWhite]: isWithe || color })}
                                href={'/'+url}
                                key={id}
                            >
                                {title}
                            </Link>
                        ))}
                    </div>
                    <Search />
                </div>
            </Container>
        </section>
    );
};

export { InformationBar };
