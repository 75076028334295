import { Container } from '@/components/ui/container';
import { Fragment, useContext, useMemo, useState } from 'react';
import { GlobalDatasContext } from '@/context/globalDatas';
import { LINKS_FROM_MENU_TITLES } from '@/constants/words';
import Image from 'next/image';
import Link from 'next/link';
import useWindowSize from '@/hooks/useWindowSize';
import classNames from 'classnames';
import classes from './index.module.css';
import { useRouter } from 'next/router';

const Footer: React.FC = () => {
    const { width } = useWindowSize();
    const { pathname } = useRouter();
    const {
        menus: { data, isLoading },
        citys: { data: citys }
    } = useContext(GlobalDatasContext);

    const [ imageError, setImageError ] = useState(false);

    const handleImageError = () => setImageError(true);

    const isMobile = useMemo(() => Boolean(Number(width) < 1024), [width]);

    const linkGroup = <div className={classNames(classes.linkGroup, classes.contactInfo)}>
        <h3 className={classes.groupName}>Contact Info</h3>
        <ul>
            <li className={classes.link}>
                <p>
                    Head Office: 4350 N Hamilton Rd
                    Columbus, OH 43230 
                </p>
            </li>
            { data?.contactInfo?.[LINKS_FROM_MENU_TITLES.phone]?.url &&
            data?.contactInfo?.[LINKS_FROM_MENU_TITLES.phone]?.title &&
                <li className={classes.link}>
                    <p>
                        {data?.contactInfo[LINKS_FROM_MENU_TITLES.phone].title}:&nbsp;
                        <Link href={`tel:${data?.contactInfo[LINKS_FROM_MENU_TITLES.phone].url}`}>
                            {data?.contactInfo[LINKS_FROM_MENU_TITLES.phone].url}
                        </Link>
                    </p>
                </li>
            }
            { data?.contactInfo?.[LINKS_FROM_MENU_TITLES.mail]?.url &&
            data?.contactInfo?.[LINKS_FROM_MENU_TITLES.mail]?.title &&
                <li className={classes.link}>
                    <p className={classes.mail}>
                        {data?.contactInfo[LINKS_FROM_MENU_TITLES.mail].title}:&nbsp;
                        <Link href={`mailto:${data?.contactInfo[LINKS_FROM_MENU_TITLES.mail].url}`}>
                            {data?.contactInfo[LINKS_FROM_MENU_TITLES.mail].url}
                        </Link>
                    </p>
                </li>
            }
        </ul>
    </div>;

    if(data === null || citys === null || pathname === '/404' || isLoading) return null;

    return (
        <footer className={classes.footer}>
            <Container>
                <div className={classNames(classes.footerContent, {
                    [classes.footerContentMobile]: isMobile
                })}>
                    <div className={classes.wrapperLogoImg}>
                        <Link href='/'>
                            <Image
                                src={!imageError && data.logo || "/assets/images/logo.png"}
                                alt="logo"
                                className={classes.logo}
                                width={130}
                                height={95}
                                onError={handleImageError}
                                priority
                            />
                        </Link>
                        { !isMobile && linkGroup}
                        <div className={classes.social}>
                            { data.socialFooterIcons.map((Component, index) => (
                                Component && <Component key={index}/>
                            ))}
                        </div>
                    </div>
                    { isMobile && (
                        <Fragment>
                            {linkGroup}
                            <div className={classes.autoTransportText}>
                                © 2023 - Columbus Auto Transport. All Rights Reserved.
                            </div>
                            <div className={classes.termsBlock}>
                                <div className={classes.verticalRow} />
                                <Link href={'/'+data.contactInfo?.[LINKS_FROM_MENU_TITLES.termsAndConditions]?.url}>
                                    {data.contactInfo?.[LINKS_FROM_MENU_TITLES.termsAndConditions]?.title}
                                </Link>
                                <div className={classes.verticalRow} /> 
                                <Link href={'/'+data.contactInfo?.[LINKS_FROM_MENU_TITLES.privacyPolicy]?.url}>
                                    {data.contactInfo?.[LINKS_FROM_MENU_TITLES.privacyPolicy]?.title}
                                </Link>
                                <div className={classes.verticalRow} />
                            </div>
                        </Fragment>
                    )}
                    { Boolean(!isMobile && data.menuItems.length) && (
                        <ul className={classes.linkGroup}>
                            <h3 className={classes.groupName}>Quick Links</h3>
                            {Boolean(!isMobile && data.quickLinks.length) && data.quickLinks.map((linkGroup, index) => 
                                !Boolean(linkGroup.children.length) && (
                                    <li key={index} className={classes.link}>
                                        <Link href={'/'+linkGroup.url}>{linkGroup.title}</Link>
                                    </li>
                                )
                            )}
                        </ul>
                    )}

                    { Boolean(!isMobile && citys?.length) && 
                        [ citys.slice(0, Math.floor(citys.length / 2)),
                          citys.slice(Math.floor(citys.length / 2), citys.length)
                        ].map((cityGroup, index) => (
                            <ul
                                key={index} 
                                className={classNames(classes.linkGroup, {
                                    [classes.seccondGroupCity]: index !== 0
                                })}
                            >
                                { index === 0 && (
                                    <h3 className={classes.groupName}>Columbus Cities</h3>
                                )}

                                {cityGroup.map(({ id, slug, title }) => 
                                    <li key={id} className={classes.link}>
                                        <Link href={'/'+slug}>{title}</Link>
                                    </li>
                                )}
                            </ul>
                        )
                    )}
                </div>
                <div className={classes.underLine}>
                    <div className={classes.autoTransportText}>
                        © 2023 - Columbus Auto Transport. All Rights Reserved.
                    </div>
                    <div className={classes.termsBlock}>
                        <div className={classes.verticalRow} />
                        <Link href={'/'+data.contactInfo?.[LINKS_FROM_MENU_TITLES.termsAndConditions]?.url}>
                            {data.contactInfo?.[LINKS_FROM_MENU_TITLES.termsAndConditions]?.title}
                        </Link>
                        <div className={classes.verticalRow} /> 
                        <Link href={'/'+data.contactInfo?.[LINKS_FROM_MENU_TITLES.privacyPolicy]?.url}>
                            {data.contactInfo?.[LINKS_FROM_MENU_TITLES.privacyPolicy]?.title}
                        </Link>
                        <div className={classes.verticalRow} />
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export { Footer };