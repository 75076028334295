import { LINKS } from "@/constants/links";
import Link from "next/link";

export const TwitterFooterIcon = () => (
    <Link
        aria-label={LINKS.to_twitter.ariaLabel}
        target="_blank"
        href={LINKS.to_twitter.url}
    >
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9168 2.35294C20.15 2.76471 19.3235 3.03529 18.467 3.16471C19.3434 2.54118 20.0206 1.55294 20.3392 0.364706C19.5127 0.952941 18.5965 1.36471 17.6305 1.6C16.8438 0.588235 15.7384 0 14.4836 0C12.1433 0 10.2313 2.25882 10.2313 5.04706C10.2313 5.44706 10.2711 5.83529 10.3408 6.2C6.79558 5.98824 3.63871 3.97647 1.53745 0.929412C1.16898 1.67059 0.959851 2.54118 0.959851 3.45882C0.959851 5.21176 1.70674 6.76471 2.86194 7.64706C2.15488 7.64706 1.49761 7.41177 0.920016 7.05882V7.09412C0.920016 9.54118 2.39389 11.5882 4.34577 12.0471C3.7191 12.2497 3.0612 12.2778 2.42376 12.1294C2.69424 13.1323 3.22397 14.0099 3.93848 14.6387C4.65298 15.2676 5.51634 15.6161 6.40719 15.6353C4.8971 17.0476 3.02525 17.811 1.09927 17.8C0.760679 17.8 0.422088 17.7765 0.0834961 17.7294C1.97563 19.1647 4.22626 20 6.63624 20C14.4836 20 18.7957 12.3059 18.7957 5.63529C18.7957 5.41177 18.7957 5.2 18.7857 4.97647C19.6222 4.27059 20.3392 3.37647 20.9168 2.35294Z" fill="#F58020" fillOpacity="0.6"/>
        </svg>
    </Link>
);