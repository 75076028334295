const initialGroup = {
    title: '',
    list: []
};

interface IList {
    category: string;
    text: string;
}

interface IGroup {
    title: string,
    list: Array<IList>
}

export interface IContactData {
    service: boolean;
    title: string;
    description: string;
    formTitle: string;
    image: string;
    btnText: string;
    btnLink: string;
    informationGroup: IGroup,
};

export class ContactAdapter {
    constructor(
        public service: boolean,
        public title: string,
        public description: string,
        public formTitle: string,
        public image: string,
        public btnText: string,
        public btnLink: string,
        public informationGroup: IGroup,
    ) {};
  
    public static createContactData(data: any): ContactAdapter {
        let newGroup: Partial<IGroup> = {};
        const group = data?.['contact.group']?.reduce((acc: Array<IGroup>, group: any): Array<IGroup> => {
            if (group.title && typeof group.title.value === 'string') {
                newGroup = {
                    title: group.title.value,
                    list: [],
                };
                acc.push(newGroup as IGroup);
                
            } else if (group['category_'] && group['text_']) {
                let listItem: IList = {
                    category: group['category_'].value,
                    text: group['text_'].value || '',
                };
                if (newGroup.list) {
                    newGroup.list.push(listItem);
                } else {
                    newGroup.list = [listItem];
                }
            }

            return acc;
        }, [])?.[0] || initialGroup;

        const [one, two, tree, four] = group.list;
        group.list = [one, two, four, tree ]

        return new ContactAdapter(
            data.service,
            data?.['contact.title']?.value || '',
            data?.['contact.description']?.value || '',
            data?.['contact.formTitle']?.value || '',
            data?.['contact.image']?.page_image?.[0]?.image|| '',
            data?.['contact.btn_text']?.value|| '',
            data?.['contact.btn_link']?.value|| '',
            group
        );
    };
};
