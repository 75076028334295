interface ITransportServiceCard {
    title: string;
    image: string;
    link: string;
    text: string;
    btnText: string;
    id: number;
}

export interface ITransportServicesBlocksData {
    service: boolean;
    transportServicesTitle: string;
    transportServicesDescription: string;
    transportServiceCards: Array<ITransportServiceCard>;
};

export class TransportServicesAdapte {
    constructor(
        public service: boolean,
        public transportServicesTitle: string,
        public transportServicesDescription: string,
        public transportServiceCards: Array<ITransportServiceCard>
    ) {};
  
    public static transportServicesAdapteData(data: any): ITransportServicesBlocksData {
        return new TransportServicesAdapte(
            data.service,
            data?.['transport-services.transport_services_title']?.value || '',
            data?.['transport-services.transport_services_description']?.value || '',
            data?.['transport-services.transport_services_card']?.map((card, index) => ({
                title: card?.title?.value,
                image: card?.image?.page_image?.[0]?.image,
                link: card?.link?.value,
                text: card?.text?.value,
                btnText: card?.btn_text?.value,
                id: index
            })) || []
        );
    };
};