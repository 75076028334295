import { useContext, useState } from 'react';
import { Steps } from '@/models/forms';
import { RouteHistory } from './routeHistory';
import { Form } from './forms/Form';
import { MobileFormsPopupContext } from '@/context/mobileFormsPopup';
import classes from './index.module.css';

const MobileFormsPopup: React.FC = () => {
    const [ steps, setSteps ] = useState<Steps>([
        {
            id: 1,
            text: 'Select a Rout',
            active: true,
            values: {
                form: '',
                to: ''
            }
        },
        {
            id: 2,
            text: 'Select an Options',
            active: false,
            values: {
                vehicle: [],
                operable: null,
                method: null,
                time: ''
            },
        },
        {
            id: 3,
            text: 'Confirmation',
            active: false
        },
        {
            id: 4,
            text: 'Get a Quote',
            active: false,
            values: {
                name: '',
                phone: '',
                email: ''
            }
        }
    ]);
    const { isOpen } = useContext(MobileFormsPopupContext);

    if(!isOpen) return null;

    return (
        <div className={classes.root}>
            <RouteHistory steps={steps} />
            <div className={classes.form}>
                <Form
                    setSteps={setSteps}
                    id={(steps.find(step => step.active)!).id}
                    steps={steps}
                />
            </div>
        </div>
    );
};

export { MobileFormsPopup };
