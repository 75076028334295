export interface IHistoryData {
    service: boolean;
    title: string;
    firstBlockText: string;
    seccondBlockText: string;
    premiumTitle: string;
    premiumText: string;
    image: string;
};

export class HistoryAdapter {
    constructor(
        public service: boolean,
        public title: string,
        public firstBlockText: string,
        public seccondBlockText: string,
        public premiumTitle: string,
        public premiumText: string,
        public image: string,
    ) {};

    public static createHistoryData(data: any): IHistoryData {
        return new HistoryAdapter(
            data.service,
            data?.['history.title']?.value || '',
            data?.['history.first_left_block_text']?.value || '',
            data?.['history.seccond_left_block_text']?.value || '',
            data?.['history.premium_services_title']?.value || '',
            data?.['history.premium_services_text']?.value || '',
            data?.['history.image']?.page_image?.[0]?.image || ''
        );
    };
};