interface ICalculationBlocks {
    id: number;
    image: string;
    text: string;
};

export interface ICalculationData {
    calculationTitle: string;
    calculationBlocks: Array<ICalculationBlocks>;
};

export class CalculationAdapter {
    constructor(
        public calculationTitle: string,
        public calculationBlocks: Array<ICalculationBlocks>,
    ) {};
  
    public static createCalculationData(data: any): ICalculationData {
        return new CalculationAdapter(
            data?.['calculation.calculation_title']?.value || '',
            data?.['calculation.calculation_block'] ? data['calculation.calculation_block'].map((block, index) => ({
                id: index,
                image: block?.image?.page_image?.[0]?.image,
                text: block?.text?.value
            })) : [],
        );
    };
};