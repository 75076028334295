import { useContext, useRef, useState } from 'react';
import { InputUI } from '../ui/InputUI';
import { LabelUI } from '../ui/LabelUI';
import { AddIcon } from '@/public/assets/svges/AddIcon';
import { DropdownSelectUI } from '../ui/DropdownSelectUI';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnumTimeOptions } from '@/models/options';
import { handleTypeChangeName, handleTypeChangeYear, hendleTypeRemoveSpace } from '@/helper/strings';
import { MutationFunction, UseMutationResult, useMutation, useQuery } from 'react-query';
import { ISubmitFormData } from '@/models/forms';
import { getOptions, sendQuoteData } from '@/constants/service';
import { getCuurentFormatData } from '@/helper/form';
import { Autocomplete, TextField } from '@mui/material';
import { useRouter } from 'next/router';
import { LoadingUI } from '../ui/LoadingUI';
import { toast } from 'react-toastify';
import { IMaskInput } from 'react-imask';
import { ThankYouContext } from '@/context/thankYou';
import CheckBox from '../ui/CheckBoxUI';
import classNames from 'classnames';
import * as yup from "yup";
import classes from './index.module.css';

type VehicleObject = {
    [key in 'year' | 'make' | 'model']: string;
};

interface IFormData {
    from: string;
    to: string;
    vehicle: Array<VehicleObject>,
    time: EnumTimeOptions;
    name: string;
    email: string;
    phone: string;
};

const vehicleSchema = yup.object().shape({
    year: yup
        .string()
        .required('must be valid year')
        .test('is-valid-year', 'Invalid year', value => {
            const parsedYear = parseInt(value, 10);
            if (!value || isNaN(parsedYear)) return false;

            return parsedYear >= 1920 && parsedYear <= new Date().getFullYear() + 2;
        }),
    make: yup.string().min(2, 'must be at least 2 characters').max(32, 'must be at least 32 characters').required(),
    model: yup.string().min(2, 'must be at least 2 characters').max(32, 'must be at least 32 characters').required(),
});

const schema = yup.object().shape({
    from: yup
        .string()
        .test('is-valid-form', 'Invalid from input', (value) => {
            const hasString = /[a-zA-Z]{2}/.test(value!);
            const isFiveDigits = /^\d{5}$/.test(value!);
            return (hasString && !isFiveDigits) || (!hasString && isFiveDigits);
        })
        .min(2, 'must be at least 2 characters')
        .max(32, 'must be at most 32 characters')
        .required(),
    to: yup
        .string()
        .test('is-valid-to', 'Invalid to input', (value) => {
            const hasString = /[a-zA-Z]{2}/.test(value!);
            const isFiveDigits = /^\d{5}$/.test(value!);
            return (hasString && !isFiveDigits) || (!hasString && isFiveDigits);
        })
        .min(2, 'must be at least 2 characters')
        .max(32, 'must be at most 32 characters')
        .required(),
    vehicle: yup.array().of(vehicleSchema),
    name: yup.string().min(2, 'must be at least 2 characters').max(32, 'must be at least 32 characters').required(),
    phone: yup.string().required().min(14, "Invalid phone number").matches(new RegExp('[0-9]')).required(),
    time :yup.string().required(),
    email: yup.string().required().matches(
        /[A-z0-9]+@{1}[A-z0-9]+\.[A-z]{2,}$/,
      'Invalid email'
    )
});

const initialVehicleSchema = { year: "", make: "", model: "" };

const sendCall: MutationFunction<any, ISubmitFormData> = async (formData: ISubmitFormData) => {
    const response = await sendQuoteData(formData);
    const clonedResponse = response.clone();
    return await clonedResponse.json();
};

interface IProps {
    closeFormPopup?: () => void;
};

const GeneralForm: React.FC<IProps> = ({ closeFormPopup }) => {
    const formRef = useRef<HTMLFormElement>(null); 
    const { push, pathname } = useRouter();
    const { openEntryThankYou } = useContext(ThankYouContext);
    const [shippingMethod, setShippingMethod] = useState<1 | 2 | 'error' | null>(null);
    const [operableMethod, setOperableMethod] = useState<1 | 2 | 'error' | null>(null);
    const [vehicles, setVehicles] = useState<Array<VehicleObject>>([initialVehicleSchema]);
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        setValue,
        watch,
        clearErrors,
        trigger,
        control,
        formState: { errors }
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: { vehicle: [initialVehicleSchema] }
    });

    const { from, to } = watch();

    const { data: optionsFrom, isLoading: loadingFrom } = useQuery(['optionsFrom', from], getOptions(from), {
        enabled: Boolean(from)
    });

    const { data: optionsTo, isLoading: loadingTo } = useQuery(['optionsTo', to], getOptions(to), {
        enabled: Boolean(to)
    });

    const { mutate, isLoading }: UseMutationResult<
        any, unknown, ISubmitFormData, unknown
    > = useMutation(
        'quoteForm', sendCall,
        {
            onSuccess: () => {
                closeFormPopup && closeFormPopup();
                openEntryThankYou();
                if(pathname === '/thank-you') {
                    toast.success('your message is successfully sent', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    const scrollEl = document.querySelector('.thankYouPRef');
                    scrollEl?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                } else {
                    push('/thank-you')
                };
            },
            onError: () => {
                toast.error('sorry something is wrong', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    );
    
    const selectCheckBoxes = (_, name) => {
        switch(name) {
            case 'no': return setOperableMethod(2);
            case 'yes': return setOperableMethod(1);
            case 'open': return setShippingMethod(1);
            case 'closed': return setShippingMethod(2);
        };
    };

    const addVehicleList = () => setVehicles([
        ...vehicles,
        initialVehicleSchema
    ]);

    const removeVehicleList = () => {
        const updatedVehicles = vehicles.slice(0, -1);
        setVehicles(updatedVehicles);
        const currentData = getValues();
        const updatedData = { ...currentData };
        updatedData.vehicle.pop();
        reset(updatedData);
    };

    const checkErrors = (): boolean => {
        shippingMethod === null && setShippingMethod('error');
        operableMethod === null && setOperableMethod('error');
        return typeof shippingMethod !== 'number' || typeof operableMethod !== 'number'; 
    };

    const onSubmit = (data: IFormData) => {
        const isErrors = checkErrors();
        if(!isErrors && !isLoading) {
            const vehicle: Array<VehicleObject> = { ...data }.vehicle as Array<VehicleObject>;
            setVehicles([...vehicle]);
            const formData = getCuurentFormatData(data);
            mutate(formData);
        };
    };

    const callbackListnerDropdown = () => {
        formRef.current?.scroll({
            top: 9999999999,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className={classes.wrapperHeading}>
                <h2 className={classes.heading}>Need Good Content</h2>
            </div>
            <div className={classes.fromToWrapper}>
                <InputUI
                    label='From'
                    labelIcon={true}
                    error={errors.from && errors.from.message}
                    element={<Autocomplete
                        onBlur={() => clearErrors('from')}
                        className={'autocomplete'}
                        options={optionsFrom?.data || []}
                        clearOnBlur={false}
                        loading={loadingFrom}
                        value={from}
                        renderInput={(params) => <TextField
                            onSelect={(event) => {
                                const { value } = event.target as any;
                                setValue('from', value);
                            }}
                            {...params}
                            {...register('from')}
                            onChange={(event) => hendleTypeRemoveSpace({event})}
                            placeholder="City,State or ZIP"
                        />}
                    />}
                />
                <InputUI
                    label='To'
                    labelIcon={true}
                    error={errors.to && errors.to.message}
                    element={<Autocomplete
                        onBlur={() => clearErrors('to')}
                        className={'autocomplete'}
                        options={optionsTo?.data || []}
                        clearOnBlur={false}
                        loading={loadingTo}
                        renderInput={(params) => <TextField
                            {...params}
                            {...register('to')}
                            onChange={(event) => hendleTypeRemoveSpace({event})}
                            placeholder="City,State or ZIP"
                        />}
                    />}
                />
            </div>
            <div className={classes.vehicleGroup}>
                <LabelUI toolti={true} text='Vehicle' icon={true} htmlFor='Vehicle'/>
                <div className={classes.vehicleLists}>
                    { vehicles.map((_, index) => {
                        const vehicle = `vehicle[${index}]` as any;
                        const error = errors.vehicle && errors.vehicle[index] || {};
                        return (
                            <div className={classes.vehicleList} key={index}>
                                <InputUI
                                    handleChange={({ event, name }) => {
                                        handleTypeChangeYear({event});
                                        clearErrors(name as any);
                                    }}
                                    name={`${vehicle}.year`}
                                    placeholder='year'
                                    registerOption={{}}
                                    register={register}
                                    error={error.year?.message}
                                />
                                <InputUI
                                    labelIcon={true}
                                    name={`${vehicle}.make`}
                                    placeholder='Make'
                                    registerOption={{}}
                                    register={register}
                                    handleChange={({ event, name }) => {
                                        hendleTypeRemoveSpace({event});
                                        clearErrors(name as any);
                                    }}
                                    error={error.make?.message}
                                />
                                <InputUI
                                    labelIcon={true}
                                    name={`${vehicle}.model`}
                                    placeholder='Model'
                                    registerOption={{}}
                                    register={register}
                                    handleChange={({ event, name }) => {
                                        hendleTypeRemoveSpace({event});
                                        clearErrors(name as any);
                                    }}
                                    error={error.model?.message}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className={classNames('editLists', classes.editLists)}>
                    <div className={classes.addList} onClick={addVehicleList}>
                        <AddIcon />
                        <span>Add Multiple Vehicle</span>
                    </div>
                    { vehicles.length > 1 && (
                        <div className={classes.removeList} onClick={() => {
                            setTimeout(removeVehicleList);
                        }}>
                            <AddIcon rotate={45}/>
                            <span>Remove Vehicle</span>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.selectConditions}>
                <div className={classes.timeBlock}>
                    <LabelUI toolti={true} text='Time' icon={true}/>
                    <DropdownSelectUI
                        items={Object.values(EnumTimeOptions)}
                        setFomrValue={setValue}
                        getFormValues={getValues}
                        trigger={trigger}
                        register={register}
                        watch={watch}
                        name='time'
                        registerOption={{}}
                        callbackListnerDropdown={callbackListnerDropdown}
                    />
                    {errors.time?.message &&
                        <span className={classes.error}>{errors.time.message}</span>
                    }
                </div>
                <div className={classes.checkboxes}>
                    <div className={classes.shippingMethod}>
                        <LabelUI text='Shipping Method?' icon={true} toolti={true}/>
                        <div className={classes.wrapperCheckbox}>
                            <div className={classes.checkboxList} onClick={() => selectCheckBoxes(null, 'open')}>
                                <CheckBox
                                    name='open'
                                    checked={shippingMethod === 1}
                                    onChange={selectCheckBoxes}
                                />
                                <LabelUI text='Open' htmlFor='open'/>
                            </div>
                            <div className={classes.checkboxList} onClick={() => selectCheckBoxes(null, 'closed')}>
                                <CheckBox
                                    name='closed'
                                    checked={shippingMethod === 2}
                                    onChange={selectCheckBoxes}
                                />
                                <LabelUI text='Enclosed' htmlFor='closed'/>
                            </div>
                            {shippingMethod === 'error' && <span className={classes.error}>required</span>}
                        </div>
                    </div>
                    <div className={classes.operableMethod}>
                        <LabelUI text='Is It Operable?' icon={true} toolti={true} tooltiPosition='left'/>
                        <div className={classes.wrapperCheckbox}>
                            <div className={classes.checkboxList} onClick={() => selectCheckBoxes(null, 'yes')}>
                                <CheckBox name='yes' checked={operableMethod === 1} onChange={selectCheckBoxes}/>
                                <LabelUI text='Yes' htmlFor='yes'/>
                            </div>
                            <div className={classes.checkboxList} onClick={() => selectCheckBoxes(null, 'no')}>
                                <CheckBox name='no' checked={operableMethod === 2} onChange={selectCheckBoxes}/>
                                <LabelUI text='No' htmlFor='no'/>
                            </div>
                            {operableMethod === 'error' && <span className={classes.error}>required</span>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.userContact}>
                <InputUI
                    handleChange={({ event, name }) => {
                        handleTypeChangeName({ event });
                        clearErrors(name as any);
                    }}
                    name='name'
                    placeholder='Enter full name'
                    label='Name'
                    registerOption={{ minLength: 2 }}
                    register={register}
                    error={errors.name?.message}
                />
                <InputUI
                    handleChange={({ event, name }) => {
                        hendleTypeRemoveSpace({ event });
                        clearErrors(name as any);
                    }}
                    name='email'
                    placeholder='example@domain.com'
                    label='Email'
                    registerOption={{ minLength: 2 }}
                    register={register}
                    error={errors.email?.message}
                />

                <Controller
                    name='phone'
                    control={control}
                    render={({ field }) => <InputUI
                        label='Phone number'
                        error={errors.phone?.message}
                        element={<IMaskInput
                            placeholder='Enter Phone number'
                            type="tel"
                            mask="(#00) 000-0000"
                            definitions={{"#": /[1-9]/}}
                            {...register('phone')}
                            {...field}                        
                        />}
                    />}
                />
            </div>
            <button
                className={classes.sendBtn}
                onClick={checkErrors}
                type='submit'
            >
                Continue
                { isLoading && <LoadingUI type='roundSmall' /> }
            </button>
        </form>
    );
};

export { GeneralForm };