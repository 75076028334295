export const BLOGS_LIMIT = 2;
export const BLOG_CATEGORY = 2;
export const NEW_LIMIT = 2;
export const NEW_CATEGORY = 1;
export const HOME_CATEGORY_ARTICLES = 0;
export const HOME_CATEGORY_LIMIT = 1;

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL; 

export const QUOTE_FORM = BASE_URL + 'quoteForm'; // Api Qoute Form;

export const CONTACT_FORM = BASE_URL + 'contact'; // Api Contact Info;

export const LATEST_BLOGS_URL = BASE_URL + `getLast2Data?category=${BLOG_CATEGORY}&limit=${BLOGS_LIMIT}`; // Api Latest Blogs;
export const LATEST_NEWS_URL = BASE_URL + `getLast2Data?category=${NEW_CATEGORY}&limit=${NEW_LIMIT}`; // Api Latest News;

export const MENUS_URL = BASE_URL + 'menus'; // Api Menus;

export const HOME_URL = BASE_URL + 'getDynamicData/home'; // Api Home;
export const LATEST_ARTICLES_URL = BASE_URL + `getLast2Data?category=${HOME_CATEGORY_ARTICLES}&limit=${HOME_CATEGORY_LIMIT}`; // Api Latest Articles;

export const GET_QUOTE = BASE_URL + 'getDynamicData/quote'; // Api Quote;

export const GET_REVIEWS = BASE_URL + 'reviews' // Api Reviews;

export const GET_COLCULATIONS_URL = BASE_URL + 'getDynamicData/calculation'; // Api Calculation;

export const GET_TRANSPORT_SERVICES = BASE_URL + 'getDynamicData/transport-services'; // Api Transport Services; 

export const FAQ_URL = BASE_URL + 'getDynamicData/faq'; // Api Faq;

export const WHY_US_URL = BASE_URL + 'getDynamicData/why-us'; // Api Why Us;

export const HISTORY_URL = BASE_URL + 'getDynamicData/history'; // Api History;

export const HOW_IT_WORKS_URL = BASE_URL + 'getDynamicData/how-It-works'; // Api How it works;

export const AUCTION_URL = BASE_URL + 'getDynamicData/auction'; // Api Auction;

export const CHEAP_URL = BASE_URL + 'getDynamicData/cheap'; // Api Cheap;

export const CONTACT_URL = BASE_URL + 'getDynamicData/contact' // Api Contact;

export const SEARCH_URL = BASE_URL + 'search'; // Api Search;

export const ADD_COMMENT_URL = BASE_URL + 'comments'; // Api Add Comment;

export const ADD_FEETBACK_URL = BASE_URL + 'review' // Api Add review;

export const TERMS_URL = BASE_URL + 'getDynamicData/terms'; // Api Terms;

export const PRIVACY_URL = BASE_URL + 'getDynamicData/privacy'; // Api Privacy;

export const CITYS_URL = BASE_URL + 'category?slug=page'; // api Citys;

export const getOptionsByZipCodeApi = (text: string) => `https://devback.ifta.online/api/v1/zipCodeOrCity/${text}`; // api for get data by zip code;
export const getLatestCitysApi = (limit: number) => BASE_URL + `getLast2Data?category=3&limit=${limit}`;
export const getBlogsApi = (page: number) => BASE_URL  + `category?slug=blogs&page=${page}`; // Api endpoint for Blog list on specific page;
export const getNewsApi = (page: number) => BASE_URL  + `category?slug=news&page=${page}`; // Api endpoint for News list on specific page;
export const getCurrentBlogApi = (slug: string) => BASE_URL + `getDynamicData/${slug}`; // Api endpoint for specific blog by slug;
export const getCurrentNewsApi = (slug: string) => BASE_URL + `getDynamicData/${slug}`; // Api endpoint for specific News by slug;

export const getCurrentPageDataApi = (slug: string) => BASE_URL + `getDynamicData/${slug}`; // Api for dynamic pages