import { ConfirmationData, SetSteps, IVehiclesMobile } from "@/models/forms";
import { IconToBack } from "@/public/assets/svges/IconToBack";
import { useAnimation } from "@/hooks/useAnimation";
import { AuthAnimationOptions, AuthAnimations } from "@/constants/animation";
import { useEffect, useState } from "react";
import { ArrowIcon } from "@/public/assets/svges/ArrowIcon";
import classNames from "classnames";
import classes from './inedx.module.css';

interface Iprops {
    setSteps: SetSteps;
    data: ConfirmationData;
};

const ConfirmationForm: React.FC<Iprops> = ({ setSteps, data }) => {
    const [animationDivRef, animate] = useAnimation<HTMLFormElement>(AuthAnimationOptions);
    const [vehiclesMobile, setVehiclesMobile] = useState<Array<IVehiclesMobile>>(
        data.vehicle.map((vehicle) => ({ ...vehicle, isActive: false }))
    );

    useEffect(() => {
        animate(AuthAnimations.fromRight, () => {
            animationDivRef.current?.scroll({
                top: 9999999999,
                behavior: 'smooth'
            });
        });
    }, []);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        animate(AuthAnimations.toLeft, () => {
            setSteps(prevSteps => prevSteps.map(step => ({
                ...step,
                active: step.id === 4
            })));
        });
    };

    const intoBack = () => {
        setSteps(prevSteps => prevSteps.map(step => ({
            ...step,
            active: step.id === 2
        })));
    };

    const toogleVehicleLine = (lineIndex: number) => {
        const newVehicles = vehiclesMobile.map((vehicle, index) => ({
            ...vehicle,
            isActive: index === lineIndex ? !vehicle.isActive : false
        }));
        setVehiclesMobile(newVehicles);
    };
        
    return (
        <form
            className={classes.form}
            onSubmit={onSubmit}
            ref={animationDivRef}
        >
            <div className={classes.wrapperHeading}>
                <div className={classes.back} onClick={intoBack}>
                    <IconToBack />
                    <span>Edit</span>
                </div>
                <h2 className={classes.formHeading}>Get A <span>FREE</span> Quote</h2>
            </div>
            <div className={classes.lineNode}>
                <p className={classes.info}>
                    From:
                    <span>{data.form}</span>
                </p>
            </div>
            <div className={classes.lineNode}>
                <p className={classes.info}>
                    To:
                    <span>{data.to}</span>
                </p>
            </div>
            <div className={classes.lineNode}>
                <p className={classes.info}>
                    Time:
                    <span>{data.time}</span>
                </p>
            </div>
            <div className={classes.lineNode}>
                <p className={classes.info}>
                    Shipping Method?:
                    <span>{data.method === 1 ? 'Yes' : 'No'}</span>
                </p>
                <p className={classes.info}>
                    Is It Operable?:
                    <span>{data.operable === 1 ? 'Yes' : 'No'}</span>
                </p>
            </div>
            <div className={classNames(classes.lineNode, classes.mb20)}>
                <p className={classes.info}>
                    Vehicles
                </p>
            </div>
            <div className={classes.wrapperVehicles}>
                { data.vehicle.map((vehicl, index) => (
                    <div className={classNames(classes.lineNode, classes.vehicles)} key={index}>
                        <p className={classes.info}> Make <span key={index}>{vehicl.make}</span></p>
                        <p className={classes.info}> Model <span key={index}>{vehicl.model}</span></p>
                        <p className={classes.info}> Year <span key={index}>{vehicl.year}</span></p>
                    </div>
                ))}
            </div>
            <div className={classes.wrapperVehiclesMobile}>
                { vehiclesMobile.map((vehicl, index) => (
                    <div key={index} className={classes.vehicleLine}>
                        <div className={classes.vehicleLineTitle} onClick={() => toogleVehicleLine(index)}>
                            <div>
                                <p className={classes.squer}/>
                                <p className={classes.index}>{index + 1}) Vehicle</p>
                            </div>
                            <ArrowIcon color="#FFFFFF" rotate={vehicl.isActive ? 180 : 0}/>
                        </div>
                        <div className={classNames(classes.showedVehicleLine, {
                            [classes.active]: vehicl.isActive
                        })}>
                            <p className={classes.info}> Make <span key={index}>{vehicl.make}</span></p>
                            <p className={classes.info}> Model <span key={index}>{vehicl.model}</span></p>
                            <p className={classes.info}> Year <span key={index}>{vehicl.year}</span></p>
                        </div>
                    </div>
                ))}
            </div>
            <button className={classes.sendBtn} type='submit'>Continue</button>
        </form>
    );
};

export { ConfirmationForm };
