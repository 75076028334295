import { LINKS } from "@/constants/links";
import Link from "next/link";

export const TwitterIcon: React.FC = () => (
  <Link
    aria-label={LINKS.to_twitter.ariaLabel}
    target="_blank"
    href={LINKS.to_twitter.url}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_411_1583)">
        <circle cx="20" cy="20" r="20" fill="#1DA0F1" />
        <path d="M33 11.5882C32.043 12.0412 31.0115 12.3388 29.9426 12.4812C31.0363 11.7953 31.8815 10.7082 32.2792 9.40118C31.2476 10.0482 30.1042 10.5012 28.8987 10.76C27.9168 9.64706 26.5373 9 24.9713 9C22.0507 9 19.6644 11.4847 19.6644 14.5518C19.6644 14.9918 19.7142 15.4188 19.8011 15.82C15.3767 15.5871 11.4369 13.3741 8.81453 10.0224C8.35468 10.8376 8.09369 11.7953 8.09369 12.8047C8.09369 14.7329 9.02581 16.4412 10.4675 17.4118C9.58509 17.4118 8.76482 17.1529 8.04398 16.7647V16.8035C8.04398 19.4953 9.88337 21.7471 12.3193 22.2518C11.5372 22.4746 10.7162 22.5056 9.92065 22.3424C10.2582 23.4456 10.9193 24.4109 11.811 25.1026C12.7027 25.7944 13.7802 26.1777 14.892 26.1988C13.0074 27.7523 10.6713 28.5921 8.26769 28.58C7.84512 28.58 7.42256 28.5541 7 28.5024C9.36138 30.0812 12.1702 31 15.1778 31C24.9713 31 30.3528 22.5365 30.3528 15.1988C30.3528 14.9529 30.3528 14.72 30.3403 14.4741C31.3843 13.6976 32.2792 12.7141 33 11.5882Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_411_1583">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Link>
)