import { ISubmitFormData, SetSteps, Steps } from '@/models/forms';
import { IconToBack } from '@/public/assets/svges/IconToBack';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, AuthAnimations } from '@/constants/animation';
import { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCuurentFormatData } from '@/helper/form';
import { MutationFunction, UseMutationResult, useMutation } from 'react-query';
import { sendQuoteData } from '@/constants/service';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { MobileFormsPopupContext } from '@/context/mobileFormsPopup';
import { handleTypeChangeName } from '@/helper/strings';
import { IMaskInput } from 'react-imask';
import * as yup from "yup";
import classes from './index.module.css';
import { ThankYouContext } from '@/context/thankYou';

interface Iprops {
    setSteps: SetSteps;
    steps: Steps
};

interface IformData {
    name: string;
    email: string;
    phone: string;
}

const schema = yup.object().shape({
    name: yup.string().min(2).max(32).required(),
    phone: yup.string().required("Please enter phone number").min(12, "Invalid phone number"),
    email: yup.string().matches(
        /[A-z0-9]+@{1}[A-z0-9]+\.[A-z]{2,}$/,
      'Invalid email'
    )
    .required('Email is required'),
});

const sendCall: MutationFunction<any, ISubmitFormData> = async (formData: ISubmitFormData) => {
    
    const response = await sendQuoteData(formData);
    const clonedResponse = response.clone();
    const responseData = await clonedResponse.json();

    return responseData
};

const QuoteForm: React.FC<Iprops> = ({ setSteps, steps }) => {
    const { push, pathname } = useRouter();
    const { closeMobileFormsPopup } = useContext(MobileFormsPopupContext);
    const [animationDivRef, animate] = useAnimation<HTMLFormElement>(AuthAnimationOptions);
    const { openEntryThankYou } = useContext(ThankYouContext);
    const {
        register,
        handleSubmit,
        reset,
        control,
        clearErrors,
        formState: { errors }
    } = useForm<IformData>({
        resolver: yupResolver(schema),
    });

    // Mutation
    const { mutate, isLoading }: UseMutationResult<
        any, unknown, ISubmitFormData, unknown
    > = useMutation('quoteForm', sendCall, {
        onSuccess: () => {
            reset();
            closeMobileFormsPopup();
            openEntryThankYou();
            if(pathname === '/thank-you') {
                toast.success('your message is successfully sent', {
                    position: toast.POSITION.TOP_RIGHT
                });
                const scrollEl = document.querySelector('.thankYouPRef');
                scrollEl?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            } else {
                push('/thank-you');
            };
        },
        onError: () => {
            toast.error('sorry something is wrong', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    });

    useEffect(() => {
        animate(AuthAnimations.fromRight);
    }, []);

    const intoBack = () => {
        setSteps(prevSteps => prevSteps.map(step => ({
            ...step,
            active: step.id === 3
        })));
    };

    const onSubmit = (data: IformData) => {
        if(!isLoading) {
            setSteps(prevSteps => prevSteps.map((step: any) => ({
                ...step,
                ...(step.id === 4 && { 
                    values: data
                })
            })));
            const formData = getCuurentFormatData(steps);
            mutate(formData);
        };
    };

    return (
        <form
            className={classes.form}
            ref={animationDivRef}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={classes.wrapperHeading}>
                <div className={classes.back} onClick={intoBack}>
                    <IconToBack />
                    <span>Edit</span>
                </div>
                <h2 className={classes.formHeading}>Get A <span>FREE</span> Quote</h2>
            </div>
            <div className={classes.wrapperInput}>
                <div className={classes.name}>
                    <label className={classes.formLable}>Name</label>
                    <input
                        {...register("name")}
                        onChange={(event) => {
                            handleTypeChangeName({ event });
                            clearErrors('name');
                        }}
                        placeholder='Enter full name'
                        className={classes.formInput}
                    />
                    {errors.name && <p className={classes.error}>{errors.name.message}</p>}
                </div>
                <div className={classes.phone}>
                    <label className={classes.formLable}>Phone number</label>
                    <Controller
                        name='phone'
                        control={control}
                        render={({ field }) => <IMaskInput
                            className={classes.formInput}
                            placeholder='Enter Phone number'
                            type="tel"
                            mask="(#00) 000-0000"
                            definitions={{"#": /[1-9]/}}
                            {...register('phone')}
                            {...field}                        
                        />}
                    />
                    {errors.phone && <p className={classes.error}>{errors.phone.message}</p>}
                </div>
            </div>
            <div className={classes.email}>
                <div>
                    <label className={classes.formLable}>Email</label>
                    <input
                        {...register("email")}
                        placeholder='example@domain.com'
                        className={classes.formInput}
                    />
                    {errors.email && <p className={classes.error}>{errors.email.message}</p>}
                </div>
            </div>
            <button className={classes.sendBtn} type='submit'>
                Continue
                {isLoading && <LoadingUI type='roundSmall'/>}
            </button>
        </form>
    );
};

export { QuoteForm };