import { GlobalDatasProps } from "..";
import { AuctionAdapter } from "@/models/adapter/auctionAdapter";

export const setAuctionData = (state: GlobalDatasProps, action: {
    type: 'SET_AUCTION_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = AuctionAdapter.createAuctionData(payload.data);
    return {
        ...state,
        auction: {
            data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setAuctionError = (state: GlobalDatasProps, action: {
    type: 'SET_AUCTION_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        auction: {
            ...state.auction,
            error
        }
    };
};

export const setAuctionLoading = (state: GlobalDatasProps, action: {
    type: 'SET_AUCTION_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        auction: {
            ...state.auction,
            isLoading
        }
    };
};