import { CalculationAdapter } from "@/models/adapter/calculationAdapter";
import { GlobalDatasProps } from "..";

export const setColculationData = (state: GlobalDatasProps, action: {
    type: 'SET_COLCULATIONS_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = CalculationAdapter.createCalculationData(payload.data);
    return {
        ...state,
        calculation: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};