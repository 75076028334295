import { GlobalDatasProps } from "..";
import { setMenuData, setMenuError, setMenuLoading } from "./menu";
import { setHomeData, setHomeError, setHomeLoading } from "./home";
import { setQuoteData, setQuoteError, setQuoteLoading } from "./quote";
import { setLatestArticlesData } from "./latestArticles";
import { setFaqData, setFaqError, setFaqLoading } from "./faq";
import { setWhyUsData, setWhyUsError, setWhyUsLoading } from "./whyUs";
import { setHistoryData, setHistoryError, setHistoryLoading } from "./history";
import { setAuctionData, setAuctionError, setAuctionLoading } from "./auction";
import { setCheapData, setCheapError, setCheapLoading } from "./cheap";
import { setColculationData } from "./colculation";
import { setTransportServiceData } from "./transportService";
import { ReducerAction } from "../actionTypes";
import { setTermsData, setTermsError } from "./terms";
import { setPrivacyData, setPrivacyError } from "./privacy";
import { setContactData, setContactError } from "./contact";
import { setHowItWorksData, setHowItWorksError, setHowItWorksLoading } from "./howItWorks";
import { setCitysData, setCitysError, setCitysLoading } from "./citys";
import { setReviewsData, setReviewsError } from "./reviews";

const reducerDictionary = {
    // MENU
    SET_MENU_DATA: setMenuData,
    SET_MENU_ERROR: setMenuError,
    SET_MENU_LOADING: setMenuLoading,

    // CITYS
    SET_CITYS_DATA: setCitysData,
    SET_CITYS_ERROR: setCitysError,
    SET_CITYS_LOADING: setCitysLoading,

    // HOME
    SET_HOME_DATA: setHomeData,
    SET_HOME_ERROR: setHomeError,
    SET_HOME_LOADING: setHomeLoading,

    // QUOTE
    SET_QUOTE_DATA: setQuoteData,
    SET_QUOTE_ERROR: setQuoteError,
    SET_QUOTE_LOADING: setQuoteLoading,

    // LATEST ARTICLES
    SET_HOME_DATA_LATEST_ARTICLES: setLatestArticlesData,

    // FAQ
    SET_FAQ_DATA: setFaqData,
    SET_FAQ_ERROR: setFaqError,
    SET_FAQ_LOADING: setFaqLoading,

    // WHY US
    SET_WHY_US_DATA: setWhyUsData,
    SET_WHY_US_ERROR: setWhyUsError,
    SET_WHY_US_LOADING: setWhyUsLoading,

    // HISTORY
    SET_HISTORY_DATA: setHistoryData,
    SET_HISTORY_ERROR: setHistoryError,
    SET_HISTORY_LOADING: setHistoryLoading,

    // HOW IT WORKS
    SET_HOW_IT_WORKS_DATA: setHowItWorksData,
    SET_HOW_IT_WORKS_ERROR: setHowItWorksError,
    SET_HOW_IT_WORKS_LOADING: setHowItWorksLoading,

    // AUCTION
    SET_AUCTION_DATA: setAuctionData,
    SET_AUCTION_ERROR: setAuctionError,
    SET_AUCTION_LOADING: setAuctionLoading,

    // CHEAP
    SET_CHEAP_DATA: setCheapData,
    SET_CHEAP_ERROR: setCheapError,
    SET_CHEAP_LOADING: setCheapLoading,

    // COLCULATIONS
    SET_COLCULATIONS_DATA: setColculationData,

    // TRANSPORT SERVICES
    SET_TRANSPORT_SERVICES_DATA: setTransportServiceData,

    // TERMS
    SET_TERMS_DATA: setTermsData,
    SET_TERMS_ERROR: setTermsError,

    // PRIVACY
    SET_PRIVACY_DATA: setPrivacyData,
    SET_PRIVACY_ERROR: setPrivacyError,

    // CONTACT
    SET_CONTACT_DATA: setContactData,
    SET_CONTACT_ERROR: setContactError,

    // REVIEWS
    SET_REVIEWS_DATA: setReviewsData,
    SET_REVIEWS_ERROR: setReviewsError
};

const reducer = (
    state: GlobalDatasProps,
    action: ReducerAction
): GlobalDatasProps => reducerDictionary[action.type] ? reducerDictionary[action.type](state, action) : state;

export { reducer };