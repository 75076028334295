import { WORDS } from "@/constants/words";
import { ReducerAction } from "../../actionTypes";

export const setReviewsDataAction = (data: any): ReducerAction => ({
    type: 'SET_REVIEWS_DATA', payload: data
});

export const setReviewsErrorAction = (error: any): ReducerAction => ({
    type: 'SET_REVIEWS_ERROR',
    payload: { error: error && error.message || WORDS.failedMessage}
});