
import { createContext } from "react";
import { useState } from "react";

interface ReviewsFormProps {
  isOpen: boolean;
  closeReviewsForm: () => void;
  openReviewsForm: () => void;
};

const ReviewsFormContext = createContext<ReviewsFormProps>({
  isOpen: false,
  closeReviewsForm: () => {},
  openReviewsForm: () => {}
});

interface ReviewsFormProviderProps {
  children: React.ReactNode;
};

const ReviewsFormProvider: React.FC<ReviewsFormProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openReviewsForm = () => setIsOpen(true);
  const closeReviewsForm = () => setIsOpen(false);

  return (
    <ReviewsFormContext.Provider value={{ isOpen, closeReviewsForm, openReviewsForm }}>
      {children}
    </ReviewsFormContext.Provider>
  );
};

export { ReviewsFormContext, ReviewsFormProvider };