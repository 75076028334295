
import { createContext } from "react";
import { useState } from "react";

interface FormPopupContextProps {
  isOpen: boolean;
  openFormPopup: () => void;
  closeFormPopup: () => void;
};

const FormPopupContext = createContext<FormPopupContextProps>({
  isOpen: false,
  openFormPopup: () => {},
  closeFormPopup: () => {}
});

interface FormPopupProviderProps {
  children: React.ReactNode;
};

const FormPopupProvider: React.FC<FormPopupProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openFormPopup = () => setIsOpen(true);
  const closeFormPopup = () => setIsOpen(false);
  
  return (
    <FormPopupContext.Provider value={{ isOpen, openFormPopup, closeFormPopup }}>
      {children}
    </FormPopupContext.Provider>
  );
};

export { FormPopupContext, FormPopupProvider };