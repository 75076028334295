import { GlobalDatasProps } from "..";
import { HowItWorksAdapter } from "@/models/adapter/howItWorksAdapter";

export const setHowItWorksData = (state: GlobalDatasProps, action: {
    type: 'SET_HOW_IT_WORKS_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = HowItWorksAdapter.createHowItWorksData(payload.data);
    return {
        ...state,
        howItWorks: {
            data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setHowItWorksError = (state: GlobalDatasProps, action: {
    type: 'SET_HOW_IT_WORKS_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        howItWorks: {
            ...state.howItWorks,
            error
        }
    };
};

export const setHowItWorksLoading = (state: GlobalDatasProps, action: {
    type: 'SET_HOW_IT_WORKS_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        howItWorks: {
            ...state.howItWorks,
            isLoading
        }
    };
};