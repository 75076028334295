import { FaqAdapter } from "@/models/adapter/faqAdapter";
import { GlobalDatasProps } from "..";

export const setFaqData = (state: GlobalDatasProps, action: {
    type: 'SET_FAQ_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = FaqAdapter.createfaqData(payload.data);
    return {
        ...state,
        faq: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setFaqError = (state: GlobalDatasProps, action: {
    type: 'SET_FAQ_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        faq: {
            ...state.faq,
            error
        }
    };
};

export const setFaqLoading = (state: GlobalDatasProps, action: {
    type: 'SET_FAQ_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        faq: {
            ...state.faq,
            isLoading
        }
    };
};