import { GlobalDatasProps } from "..";
import { ContactAdapter } from "@/models/adapter/contactAdapter";

export const setContactData = (state: GlobalDatasProps, action: {
    type: 'SET_CONTACT_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = ContactAdapter.createContactData(payload.data);
    return {
        ...state,
        contact: {
            data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setContactError = (state: GlobalDatasProps, action: {
    type: 'SET_CONTACT_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        contact: {
            ...state.contact,
            error
        }
    };
};