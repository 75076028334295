import { WORDS } from "@/constants/words";
import { ReducerAction } from "../../actionTypes";

export const setCitysDataAction = (data: any): ReducerAction => ({
    type: 'SET_CITYS_DATA', payload: data
})

export const setCitysErrorAction = (error: any): ReducerAction => ({
    type: 'SET_CITYS_ERROR',
    payload: { error: error && error.message || WORDS.failedMessage}
});

export const setCitysIsLoadingAction = (isLoading: boolean): ReducerAction => ({
    type: 'SET_CITYS_LOADING',
    payload: isLoading
});