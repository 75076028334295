export interface ICheapData {
    service: boolean;
    title: string;
    image: string;
    firstBlockText: string;
    seccondBlockText: string;
    shippingTitle: string;
    shippingFirstText: string;
    shippingSeccondText: string;
    transportTitle: string;
    transportImage: string;
};

export class CheapAdapter {
    constructor(
        public service: boolean,
        public title: string,
        public image: string,
        public firstBlockText: string,
        public seccondBlockText: string,
        public shippingTitle: string,
        public shippingFirstText: string,
        public shippingSeccondText: string,
        public transportTitle: string,
        public transportImage: string,
    ) {};
  
    public static createCheapData(data: any): CheapAdapter {
        return new CheapAdapter(
            data.service,
            data?.['cheap.title']?.value || '',
            data?.['cheap.image']?.page_image?.[0]?.image || '',
            data?.['cheap.first_block_text']?.value || '',
            data?.['cheap.seccond_block_text']?.value || '',
            data?.['cheap.shipping_title']?.value || '',
            data?.['cheap.shipping_first_text']?.value || '',
            data?.['cheap.shipping_seccond_text']?.value || '',
            data?.['cheap.transport_title']?.value || '',
            data?.['cheap.transport_image']?.page_image?.[0]?.image
        );
    };
};