import { IContactInfo } from "@/components/contactUs/Content";
import { ISubmitFormData } from "@/models/forms";
import {
    LATEST_BLOGS_URL,
    LATEST_NEWS_URL,
    getBlogsApi,
    getCurrentBlogApi,
    MENUS_URL,
    getNewsApi,
    getCurrentNewsApi,
    HOME_URL,
    LATEST_ARTICLES_URL,
    FAQ_URL,
    SEARCH_URL,
    getCurrentPageDataApi,
    GET_COLCULATIONS_URL,
    GET_TRANSPORT_SERVICES,
    GET_QUOTE,
    WHY_US_URL,
    HISTORY_URL,
    AUCTION_URL,
    CHEAP_URL,
    TERMS_URL,
    PRIVACY_URL,
    CONTACT_URL,
    HOW_IT_WORKS_URL,
    CITYS_URL,
    getLatestCitysApi,
    QUOTE_FORM,
    getOptionsByZipCodeApi,
    CONTACT_FORM,
    GET_REVIEWS,
    ADD_COMMENT_URL,
    ADD_FEETBACK_URL
} from "./api";

const headers = {
    'Content-Type': 'application/json'
};

// GET Blogs;
export const getBlogs = (page: number) => () => fetch(getBlogsApi(page)).then(res =>res.json()); 
export const getCurrentBlog = (slug: string) => {
    return slug !== String(undefined) ? () => fetch(getCurrentBlogApi(slug)).then(res =>res.json()) : () => {};
};

export const getLatestBlogsBlog = (slug: string) => {
    return slug !== String(undefined) ? () => fetch(LATEST_BLOGS_URL).then(res =>res.json()) : () => {};
};

// GET News;
export const getNews = (page: number) => () => fetch(getNewsApi(page)).then(res =>res.json()); 
export const getCurrentNew = (slug: string) => {
    return slug !== String(undefined) ? () => fetch(getCurrentNewsApi(slug)).then(res =>res.json()) : () => {};
};
export const getLatestNewsNew = (slug: string) => {
    return slug !== String(undefined) ? () => fetch(LATEST_NEWS_URL).then(res =>res.json()) : () => {};
};

// GET Citys
export const getCitys = () => () => fetch(CITYS_URL).then(res => res.json());

export const getLatestCitys = (limit: number) => () => fetch(getLatestCitysApi(limit)).then(res => res.json());

// GET Menus
export const getMenus = () => () => fetch(MENUS_URL).then(res => res.json());

// GET Home
export const getHome = () => () => fetch(HOME_URL).then(res => res.json()); 

// get Quote
export const getQuote = () => () => fetch(GET_QUOTE).then(res => res.json());

// get Reviews
export const getReviews = () => () => fetch(GET_REVIEWS).then(res => res.json());

// GET Latest Articles
export const getLatestArticles = () => () => fetch(LATEST_ARTICLES_URL).then(res => res.json());

// GET Calculation Blocks
export const getCalculations = () => () => fetch(GET_COLCULATIONS_URL).then(res => res.json());

// GET Transport Services
export const getTransportServices = () => () => fetch(GET_TRANSPORT_SERVICES).then(res => res.json());

// GET Faq
export const getFaq = () => () => fetch(FAQ_URL).then(res => res.json());

// GET History
export const getHistory = () => () => fetch(HISTORY_URL).then(res => res.json());

// GET How it works
export const getHowItWorks = () => () => fetch(HOW_IT_WORKS_URL).then(res => res.json());

// GET Auction
export const getAuction = () => () => fetch(AUCTION_URL).then(res => res.json());

// GET Cheap
export const getCheap = () => () => fetch(CHEAP_URL).then(res => res.json());

// GET Why Us
export const getWhyUs = () => () => fetch(WHY_US_URL).then(res => res.json());

// GET ZIP, ADDRESS, CITY, DATA BY ZIP CODE AND CITY
export const getOptions = (text: string) => () => fetch(getOptionsByZipCodeApi(text)).then(res => res.json());

// POST Search
export const sendSearch = (postData: { text: string; page: number }) => fetch(SEARCH_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(postData)
});

// POST Comment
export const addComment = (commentPayload) => fetch(ADD_COMMENT_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(commentPayload)
});

export const addFeedbackCall = (feetbackPayload) => fetch(ADD_FEETBACK_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(feetbackPayload)
});

// GET Terms
export const getTerms = () => () => fetch(TERMS_URL).then(res => res.json());

// GET Privacy
export const getPrivacy = () => () => fetch(PRIVACY_URL).then(res => res.json());

// GET Contact
export const getContact = () => () => fetch(CONTACT_URL).then(res => res.json());

// POST Quote data
export const sendQuoteData = (formData: ISubmitFormData) => {
    return fetch(QUOTE_FORM, {
        method: 'POST',
        headers,
        body: JSON.stringify(formData)
    });
};

// POST Contact Info
export const sendContactInfo = (formData: IContactInfo) => {
    return fetch(CONTACT_FORM, {
        method: 'POST',
        headers,
        body: JSON.stringify(formData)
    });
};

// GER data with slug for dynamic pages from getServerSideProps
export const getCurrentPageData = async (slug: string) => {
    const res = await fetch(getCurrentPageDataApi(slug), {
        method:'GET',
        headers
    });
    const data = await res.json();
    return data;
};

// GER data with slug for dynamic pages from useQuery hook
export const getCurrentPageDataFromComp = (slug: string) => {
    return () => fetch(getCurrentPageDataApi(slug)).then(res => res.json());
};