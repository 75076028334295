export interface ICitysData {
    id: number;
    slug: string;
    title: string;
};

export class CitysAdapter {
    constructor(
        public id: number,
        public slug: string,
        public title: string
    ) {};
  
    public static createCitysData(data: any, index: number): CitysAdapter {
        return new CitysAdapter(
            data?.id || index,
            data?.slug || "",
            data?.title || ""
        );
    };
};