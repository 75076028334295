
import React, { createContext } from "react";
import { useReducer } from "react";
import { reducer } from "./reducer";
import { IHomeData } from "@/models/adapter/homeAdapter";
import { ICalculationData } from "@/models/adapter/calculationAdapter";
import { ITransportServicesBlocksData } from "@/models/adapter/transportServicesAdapter";
import { IQuoteSliceData } from "@/models/adapter/quoteAdapter";
import { IFaqData } from "@/models/adapter/faqAdapter";
import { IWhyUsData } from "@/models/adapter/whyUsAdapter";
import { IHistoryData } from "@/models/adapter/historyAdapter";
import { IAuctionData } from "@/models/adapter/auctionAdapter";
import { ICheapData } from "@/models/adapter/cheapAdapter";
import { ReducerAction } from "./actionTypes";
import { ITermsData } from "@/models/adapter/termsAdapter";
import { IPrivacyData } from "@/models/adapter/privacyAdapter";
import { IContactData } from "@/models/adapter/contactAdapter";
import { ICitysData } from "@/models/adapter/citysAdapter";
import { ReviewsAdapter } from "@/models/adapter/reviewsAdapter";

interface SliceType<T = any> {
    error: string | null | boolean;
    isLoading: boolean;
    data: T | null;
};

interface ILatestArticles {
    blogs: Array<Record<string, any>>;
    news: Array<Record<string, any>>;
};

interface IHomeSliceData extends IHomeData {
    latestArticles?: ILatestArticles;
};

type SliceDataType<T> = SliceType<T>;

class SliceInitialState implements SliceType {
    error: null;
    isLoading: boolean;
    data: null;

    constructor () {
        this.error = null;
        this.isLoading = false;
        this.data = null;
    };
};

export interface GlobalDatasProps {
    menus: SliceType;
    citys: SliceDataType<Array<ICitysData>>;
    home: SliceDataType<IHomeSliceData>;
    quote: SliceDataType<IQuoteSliceData>;
    faq: SliceDataType<IFaqData>;
    whyUs: SliceDataType<IWhyUsData>;
    history: SliceDataType<IHistoryData>;
    howItWorks: SliceDataType<IHistoryData>;
    auction: SliceDataType<IAuctionData>;
    cheap: SliceDataType<ICheapData>;
    calculation: SliceDataType<ICalculationData>;
    transportServices: SliceDataType<ITransportServicesBlocksData>;
    terms: SliceDataType<ITermsData>;
    privacy: SliceDataType<IPrivacyData>;
    contact: SliceDataType<IContactData>;
    reviews: SliceDataType<ReviewsAdapter>;
    dispatch: (action: ReducerAction) => void;
};

type ReducerDispatch = (action: ReducerAction) => void;

type UseReducerReturn = [GlobalDatasProps, ReducerDispatch];

interface IContext {
    children: React.ReactNode;
};

const initialValue: GlobalDatasProps = {
    menus: new SliceInitialState(),
    citys: new SliceInitialState(),
    home: new SliceInitialState(),
    quote: new SliceInitialState(),
    faq: new SliceInitialState(),
    whyUs: new SliceInitialState(),
    history: new SliceInitialState(),
    howItWorks: new SliceInitialState(),
    auction: new SliceInitialState(),
    cheap: new SliceInitialState(),
    calculation: new SliceInitialState(),
    transportServices: new SliceInitialState(),
    terms: new SliceInitialState(),
    privacy: new SliceInitialState(),
    contact: new SliceInitialState(),
    reviews: new SliceInitialState(),
    dispatch: () => {}
};

const GlobalDatasContext = createContext<GlobalDatasProps>(initialValue);

const GlobalDatasProvider: React.FC<IContext> = ({ children }) => {
    const [globalState, dispatch]: UseReducerReturn = useReducer(reducer, initialValue);

    return (
        <GlobalDatasContext.Provider value={{ ...globalState, dispatch }}>
            {children}
        </GlobalDatasContext.Provider>
    );
};

export { GlobalDatasContext, GlobalDatasProvider };
