import { useScrollPositionWindow } from '@/hooks/useScrollPositionWindow';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classes from './index.module.css';
import classNames from 'classnames';

const ScrollTopIcon: React.FC = () => {
    const { pathname } = useRouter();
    const [ to, setTo ] = useState<'top' | 'bottom'>('top');
    const scrollPosition = useScrollPositionWindow();

    useEffect(() => {
        if (scrollPosition >= 300 && to !== 'top') {
            setTo('top');
        } else if (scrollPosition < 300 &&  to !== 'bottom') {
            setTo('bottom');
        };
    }, [scrollPosition]);

    const scrollIntoTop = () => window.scroll({
        top: to === 'top' ? 0 : 9999999999,
        left: 0,
        behavior: 'smooth'
    });

    if('/404' === pathname) return null;

    return (
        <div className={classNames(classes.scrollTopIcon, {
            [classes.toTop]: to === 'top',
            [classes.toBottom]: to === 'bottom',
        })} onClick={scrollIntoTop}>
            <span>
                <svg
                    style={{ transform: 'rotate(270deg)' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="6 0 16 16"
                    fill="none"
                >
                    <path
                        d={`
                            M21.2071
                            8.70711C21.5976
                            8.31658
                            21.5976
                            7.68342
                            21.2071
                            7.29289L14.8431
                            0.928932C14.4526
                            0.538408
                            13.8195
                            0.538408
                            13.4289
                            0.928932C13.0384
                            1.31946
                            13.0384
                            1.95262
                            13.4289
                            2.34315L19.0858
                            8L13.4289
                            13.6569C13.0384
                            14.0474
                            13.0384
                            14.6805
                            13.4289
                            15.0711C13.8195
                            15.4616
                            14.4526
                            15.4616
                            14.8431
                            15.0711L21.2071
                            8.70711ZM0.5
                            9H20.5V7H0.5V9Z
                        `}
                        fill="#FFFFFF"
                    />
                </svg>
            </span>
        </div>
    );
};

export { ScrollTopIcon };