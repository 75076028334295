import { ISubmitFormData } from "@/models/forms"

export const getCuurentFormatData = (data: any): ISubmitFormData  => {
    const { form, from, to } = (data?.[0]?.values || data);
    const { vehicle, operable, method, time } = (data?.[1]?.values || data);
    const { name, phone, email } = (data[3]?.values || data);

    return {
        from: form || from, to,vehicle, name, phone, email, time,
        operable: operable === 2 ? 'no' : 'yes',
        shipping: method === 2 ? 'enclosed': 'open'
    };
};