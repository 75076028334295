import { GlobalDatasProps } from "..";

export const setLatestArticlesData = (state: GlobalDatasProps, action: {
    type: 'SET_HOME_DATA_LATEST_ARTICLES';
    payload: any | null;
}) => {
    const { payload: { data: latestArticles } } = action;
    if(state.home.data) {
        state.home.data.latestArticles = latestArticles;
    };
    return { ...state };
};