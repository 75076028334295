export interface IQuestions {
    question: string;
    answer: string;
    id: number;
}

export interface IFaqData {
    service: boolean;
    faqTitle: string;
    subTitle: string;
    questions: Array<IQuestions>;
};

export class FaqAdapter {
    constructor(
        public service: boolean,
        public faqTitle: string,
        public subTitle: string,
        public questions: Array<IQuestions>
    ) {};
  
    public static createfaqData(data: any): IFaqData {
        return new FaqAdapter(
            data.service,
            data?.['faq.title']?.value || '',
            data?.['faq.sub_title'].value || '',
            data?.['faq.questions']?.map((question, index): IQuestions => ({
                answer: question?.answer?.value,
                question: question?.question?.value,
                id: index
            })) || []
        );
    };
};