import React, { Fragment } from 'react';
import Head from 'next/head';
import { Footer } from './Footer';
import { ScrollTopIcon } from './ScrollTopIcon';
import { Header } from './Header';
import { SideBar } from './SideBar';
import { SidebarProvider } from '@/context/sideBar';
import { SocialLinks } from './SocialLinks';
import { FormPopupProvider } from '@/context/formPopup';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MobileFormsPopupProvider } from '@/context/mobileFormsPopup';
import { MobileFormsPopup } from './MobileFormsPopup';
import { GlobalDatasProvider } from '@/context/globalDatas';
import { RouteProvider } from '@/context/route';
import { ReviewsFormProvider } from '@/context/reviewsForm';
import { FeedbackForm } from '../CustomerReviews/FeedbackForm';
import { ToastContainer } from 'react-toastify';
import { ThankYouProvider } from '@/context/thankYou';
import classes from './index.module.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

interface IProps {
  pageTitle?: string;
  children: React.ReactNode;
};

const ContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThankYouProvider>
      <RouteProvider>
        <GlobalDatasProvider>
          <MobileFormsPopupProvider>
            <FormPopupProvider>
              <SidebarProvider>
                <ReviewsFormProvider>
                  {children}
                </ReviewsFormProvider>
              </SidebarProvider>
            </FormPopupProvider>
          </MobileFormsPopupProvider>
        </GlobalDatasProvider>
      </RouteProvider>
    </ThankYouProvider>
  </QueryClientProvider>
);

const Layout: React.FC<IProps> = ({ children, pageTitle = 'My Next App' }) => {
  return (
    <Fragment>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <ContextProvider>
        <Header />
        <SideBar />
        <MobileFormsPopup />
        <SocialLinks />
        <ToastContainer />
        <main className={classes.main}>
          {children}
        </main>
        <FeedbackForm />
        <Footer />
      </ContextProvider>
      <ScrollTopIcon />
    </Fragment>
)};

export default Layout;