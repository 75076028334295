interface ILinksReviewSocial {
    id: number;
    image: string;
    name: string;
    link: string
};

interface shippingService {
    title: string;
    description: string;
    image: string;
    id: number;
};

export interface IQuoteSliceData {
    service: boolean;

    bannerImage: string;

    reviewTitle: string;
    reviewSubTitle: string;
    reviewDescription: string;

    videoTitle: string;
    videoDescription: string;
    videoLink: string;
    videoLinkText: string;
    videoImage: string;
    videoPlayImage: string;

    linksReviewTitle: string;
    linksReviesSocial: Array<ILinksReviewSocial>;

    shippingServices: Array<shippingService>;

    luxuryCarLink: string;
    luxuryCarBtnText: string;
    luxuryCarDescription: string;
    luxuryCarImage: string;
    luxuryCarTitle: string;

    transportServicesTitle: string;
    transportServicesDescription: string;
}

export class QuoteAdapter {
    constructor(
        public service: boolean,

        public bannerImage: string,
    
        public reviewTitle: string,
        public reviewSubTitle: string,
        public reviewDescription: string,

        public videoTitle: string,
        public videoDescription: string,
        public videoLink: string,
        public videoLinkText: string,
        public videoImage: string,
        public videoPlayImage: string,

        public linksReviewTitle: string,
        public linksReviesSocial: Array<ILinksReviewSocial>,

        public shippingServices: Array<shippingService>,

        public luxuryCarLink: string,
        public luxuryCarBtnText: string,
        public luxuryCarDescription: string,
        public luxuryCarImage: string,
        public luxuryCarTitle: string,

        public transportServicesTitle: string,
        public transportServicesDescription: string,
    ) {};
  
    public static createQuoteData(data: any): IQuoteSliceData {
        return new QuoteAdapter(
            data.service,
            // BANNER
            data?.['quote.banner_image']?.page_image?.[0]?.image,

            // REVIEW
            data?.['quote.review_title']?.value || '',
            data?.['quote.review_sub_title']?.value || '',
            data?.['quote.review_description']?.value || '',

            // VIDEO SECTION
            data?.['quote.video_block_title']?.value || '',
            data?.['quote.video_description']?.value || '',
            data?.['quote.video_link']?.value || '',
            data?.['quote.video_link_text']?.value || '',
            data?.['quote.video_image']?.page_image?.[0]?.image || '',
            data?.['quote.video_play_image']?.page_image?.[0]?.image || '',

            // LINKS REVIEW
            data?.['quote.links_review_title']?.value || '',
            data?.['quote.links_review']?.map((link, index): ILinksReviewSocial => ({
                id: index,
                image: link?.social_image?.page_image?.[0]?.image,
                link: link?.social_link?.value,
                name: link?.social_name?.value
            })) || [],

            // SHIPPING SERVICES
            data?.['quote.shipping_services']?.map((service, index): shippingService => ({
                description: service?.description?.value,
                image: service?.image?.page_image?.[0]?.image,
                title: service?.title?.value,
                id: index
            })) || [],

            // LUXURY CAR
            data?.['quote.luxury_car_btn_link']?.value || '',
            data?.['quote.luxury_car_btn_text']?.value || '',
            data?.['quote.luxury_car_description']?.value || '',
            data?.['quote.luxury_car_image']?.page_image?.[0]?.image || '',
            data?.['quote.luxury_car_title']?.value || '',

            // TRANSPORT SERVICES
            data?.['quote.Transport_services_title']?.value || '',
            data?.['quote.Transport_services_descriptiond']?.value || '',
        );
    }
}