import { useContext, useEffect, useState } from 'react';
import { Container } from '@/components/ui/container';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, HeaderAnimations } from '@/constants/animation';
import { Burger } from '../SideBar';
import { QuoteButtonIcon } from '@/public/assets/svges/QuoteButtonIcon';
import { MobileFormsPopupContext } from '@/context/mobileFormsPopup';
import { SidebarContext } from '@/context/sideBar';
import { InformationBar } from '../InformationBar';
import { useScrollPositionWindow } from '@/hooks/useScrollPositionWindow';
import { GlobalDatasContext } from '@/context/globalDatas';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { useRouter } from 'next/router';
import useWindowSize from '@/hooks/useWindowSize';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import classes from './index.module.css';

const Header: React.FC = () => {
    const { pathname } = useRouter();
    const [ animationDivRef, animate ] = useAnimation<HTMLHeadElement>(AuthAnimationOptions);
    const { menus: { data: menuData, isLoading } } = useContext(GlobalDatasContext);
    const { toogleMobileFormsPopup, isOpen: isOpenMobileFormsPopup } = useContext(MobileFormsPopupContext);
    const { isOpen: isOpenSidebar, closeSidebar } = useContext(SidebarContext);
    const [ imageError, setImageError ] = useState(false);    
    const { width } = useWindowSize();
    const [ isOpen, setIsOpen ] = useState<Boolean>(false);
    const scrollPosition = useScrollPositionWindow();

    const isMobile = Number(width) <= 768;

    useEffect(() => {
        if(!isMobile) {
            if (scrollPosition >= 300 && isOpen === false) {
                animate(HeaderAnimations.fromTop);
                setIsOpen(true);
            } else if (scrollPosition < 300 && isOpen === true) {
                setIsOpen(false);
                animate(HeaderAnimations.toTop);
            };
        };
    }, [scrollPosition]);

    const handleImageError = () => setImageError(true);

    const getQuoteToogle = () => {
        closeSidebar();
        toogleMobileFormsPopup();
    };

    if(pathname === '/404') return null;
    if(isLoading || !menuData) return <LoadingUI type='fullPage'/>

    return (
        <header
            ref={animationDivRef}
            className={classNames(classes.header, 'header', {
                [classes.colorWhite]: isOpen,
                [classes['header-fixed']]: isOpenSidebar
            })}
        >
            { isMobile && <div className={classNames(classes.getQuote, 'getQuote')}>
                <Container>
                    <p onClick={getQuoteToogle}>
                        Get Quote Open
                        <QuoteButtonIcon rotate={isOpenMobileFormsPopup ? 180 : 0}/>
                    </p>
                </Container>
              </div>
            }
            { isOpenMobileFormsPopup === false && (
                <div className={classNames(classes.informationBar, 'informationBar')}>
                    {isMobile && (
                        <Container>
                            <Link href='/' className={classes.logo}>
                                <Image
                                    src={!imageError && menuData?.logo || "/assets/images/logo.png"}
                                    alt="hero background image"
                                    width={130}
                                    height={95}
                                    onError={handleImageError}
                                    priority
                                />
                            </Link>
                        </Container>
                    )}
                    {!isMobile && (
                        <div className={classes.menuSelects}>
                            <InformationBar
                                bg={true}
                                {...(isOpen && { rotate: 180 })}
                                {...(isOpen && { colorBlue: true })}
                            />
                        </div>
                    )}
                    {isMobile && <Burger />}
                </div>
            )}
        </header>
    );
};

export { Header };