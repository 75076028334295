import { Fragment, useContext } from 'react';
import { FormPopupContext } from '@/context/formPopup';
import { GeneralForm } from '@/components/generalForm';
import { QuoteButtonIcon } from '@/public/assets/svges/QuoteButtonIcon';
import { GlobalDatasContext } from '@/context/globalDatas';
import { useRouter } from 'next/router';
import useWindowSize from '@/hooks/useWindowSize';
import Portal from '@/components/ui/Portal';

import classes from './index.module.css';

const SocialLinks = () => {
    const { isOpen, closeFormPopup, openFormPopup } = useContext(FormPopupContext);
    const { pathname } = useRouter(); 
    const { menus: { data } } = useContext(GlobalDatasContext);
    
    const { width } = useWindowSize();

    if(Number(width) < 1024 || data === null || '/404' === pathname) return null;
    
    return (
        <Fragment>
            <div className={classes.socialLinks}>
                <div className={classes.icon} onClick={openFormPopup}>
                    <p> Get Quote </p>
                    <QuoteButtonIcon {...(isOpen && { rotate: 180 })} />
                </div>
                { data.social.map((social, index) => {
                    const IconComponent = social.iconComponent;
                    return IconComponent && <IconComponent key={index} />
                })}
            </div>
            {isOpen && (
                <Portal onClose={closeFormPopup}>
                    <GeneralForm closeFormPopup={closeFormPopup}/>
                </Portal>
            )}
        </Fragment>
    );
};

export { SocialLinks };