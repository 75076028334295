import { QuoteAdapter } from "@/models/adapter/quoteAdapter";
import { GlobalDatasProps } from "..";

export const setQuoteData = (state: GlobalDatasProps, action: {
    type: 'SET_QUOTE_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = QuoteAdapter.createQuoteData(payload.data);
    return {
        ...state,
        quote: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setQuoteError = (state: GlobalDatasProps, action: {
    type: 'SET_QUOTE_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        quote: {
            ...state.quote,
            error
        }
    };
};

export const setQuoteLoading = (state: GlobalDatasProps, action: {
    type: 'SET_QUOTE_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        quote: {
            ...state.quote,
            isLoading
        }
    };
};