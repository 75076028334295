export interface ITermsData {
    service: boolean;
    termsTitle: string;
    termsSubTitle: string;
    body: string;
};

export class TermsAdapter {
    constructor(
        public service: boolean,
        public termsTitle: string,
        public termsSubTitle: string,
        public body: string,
    ) {};
  
    public static createTermsData(data: any): any {
        return new TermsAdapter(
            data.service,
            data?.['terms.title']?.value || '',
            data?.['terms.subTitle']?.value || '',
            data?.['terms.body']?.value || '',
        );
    };
};