export const WORDS = {
    phone: '( 380 ) 867 - 0547',
    email: 'info@columbusautotransport.com',
    defaultErrorMessage: 'invalid value',
    failedMessage: 'Failed to fetch'
}

export const LINKS_FROM_MENU_TITLES = {
    whyUs: "Why Us?",
    aboutUs: "About Us",
    ourHistory: "Our History",
    whoWeServe: "Who We Serve",
    auctionAutoTransport: "Auction Auto Transport",
    usefulTips: "Useful Tips",
    beforeYouShip: "Before You Ship",
    cheapCarTransport: "Cheap Car Transport",
    topHeader: "Top Header",
    blogs: "Blogs",
    phone: "phone",
    mail: "Mail",
    left: "left",
    center: "Center",
    right: "Right",
    news: "news",
    quote: "Quote",
    fAQs: "Faqs",
    commercialCarTransport: "Commercial Car Transport",
    dealerCarTransport: "Dealer Car Transport",
    brokersandCarriers: "Brokers and Carriers",
    heavyHauling: "Heavy Hauling",
    militaryEquipmentShipping: "Military Equipment Shipping",
    contactUs: "Contact Us",
    home: "Home",
    termsAndConditions: 'Terms And Conditions',
    privacyPolicy: 'Privacy Policy',
    quickLinks: "Quick Links"
}