import classNames from 'classnames';
import classes from './index.module.css';

interface CheckBoxProps {
  checked?: boolean;
  onChange?: (checked?: boolean, name?: string) => void;
  onClick?: () => void;
  name?: string;
  register?: any;
}

const CheckBox = ({ checked, onChange, onClick, name, register }: CheckBoxProps) => {
  const handleCheckBoxChange = () => {
    onChange && onChange(!checked, name);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <input
        type="checkbox"
        id={name}
        style={{ height: '0', width: '0', opacity: 0, zIndex: -1 }}
        onChange={handleCheckBoxChange}
        {...(checked && { checked })}
        {...(name && { name })}
        {...(register && name && { ...register(name) })}
      />
      <div onClick={handleCheckBoxChange}  className={classNames(classes.checkMark, {
        [classes.active]: checked
      })}>
        {checked && <svg viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 4.5L4.5 7.5L10.5 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>}
      </div>
    </div>
  );
};

export default CheckBox;
