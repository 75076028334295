interface IWhy {
    title: string;
    subTitle: string;
    description: string;
    imageDescription: string;
    image1: string;
    image2: string;
}

interface ITeam {
    image: string;
    description: string;
}

interface ICustomer {
    title: string;
    description: string;
    image: string;
}

export interface IWhyUsData {
    service: boolean;
    whyUsTitle: string;
    why: IWhy,
    team: ITeam,
    customer: ICustomer,
    btnLink: string,
    btnText: string,
};

export class WhyUsAdapter {
    constructor(
        public service: boolean,
        public whyUsTitle: string,
        public why: IWhy,
        public team: ITeam,
        public customer: ICustomer,
        public btnLink: string,
        public btnText: string,
    ) {};
  
    public static createWhyUsData(data: any): IWhyUsData {
        return new WhyUsAdapter(
            data.service,
            data?.['why-us.title_why_us']?.value || '',
            {
                title: data?.['why-us.why_title']?.value || '',
                subTitle: data?.['why-us.why_sub_title']?.value || '',
                description: data?.['why-us.why_description']?.value || '',
                image1: data?.['why-us.why_first_image']?.page_image?.[0]?.image || '',
                image2: data?.['why-us.why_seccond_image']?.page_image?.[0]?.image || '',
                imageDescription: data?.['why-us.why_images_text']?.value || '',
            },
            {
                image: data?.['why-us.team_image']?.page_image?.[0]?.image || '',
                description: data?.['why-us.team_description']?.value
            },
            {
                title: data?.['why-us.customer_title']?.value || '',
                description: data?.['why-us.customer_description']?.value || '',
                image: data?.['why-us.customer_image']?.page_image?.[0]?.image || ''
            },
            data?.['why-us.why_btn_link']?.value || '',
            data?.['why-us.why_btn_text']?.value || '',
        );
    };
};