import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '@/context/sideBar';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, SideBarAnimations } from '@/constants/animation';
import { BurgerCloseIcon } from '@/public/assets/svges/BurgerCloseIcon';
import { GlobalDatasContext } from '@/context/globalDatas';
import { useQuery } from 'react-query';
import { getCitys, getMenus } from '@/constants/service';
import { ArrowBottmgender } from '@/public/assets/svges/ArrowBottmgender';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { Search } from '../InformationBar/Search';
import { setMenuDataAction, setMenuErrorAction } from '@/context/globalDatas/reducer/actions/menu';
import { LINKS_FROM_MENU_TITLES } from '@/constants/words';
import useWindowSize from '@/hooks/useWindowSize';
import Link from 'next/link';
import classNames from 'classnames';
import classes from './index.module.css';
import { setCitysDataAction } from '@/context/globalDatas/reducer/actions/citys';

const Burger: React.FC = () => {
    const { openSidebar, isOpen, closeSidebar } = useContext(SidebarContext);

    return isOpen ? (
        <div className={classes.closeIcon} onClick={closeSidebar}>
            <BurgerCloseIcon />
        </div>
    ) : (
        <div className={classes.bugreg} onClick={openSidebar}>
            <span className={classes.row} />
            <span className={classes.row} />
            <span className={classes.row} />
        </div>
    );
};

const SideBar: React.FC = () => {
    const { isOpen, closeSidebar } = useContext(SidebarContext);
    const { menus: { data, error }, citys: { data: citys }, dispatch } = useContext(GlobalDatasContext);
    const [ animationDivRef, animate ] = useAnimation<HTMLDivElement>(AuthAnimationOptions);
    const [ openedMenuListId, setOpenedMenuListId ] = useState<number | null>(null);
    const { width } = useWindowSize();

    const { isLoading: isLoadingMenu } = useQuery('menus', getMenus(), {
        onSuccess: data => dispatch(setMenuDataAction(data)),
        onError,
        enabled: data === null && !error
    });

    const { isLoading: isLoadingCitys } = useQuery('citys', getCitys(), {
        onSuccess: data => dispatch(setCitysDataAction(data)),
        onError,
    });

    useEffect(() => {
        animate(SideBarAnimations.fromBottom);
    }, []);

    function onError (error: any) {
        dispatch(setMenuErrorAction(error))
    };

    if(!isOpen || Number(width) > 768) return null;
    
    if(isLoadingMenu || isLoadingCitys || !data || !citys) return <LoadingUI type='fullPage'/>;

    return (
        <div className={classNames(classes.sideBar, 'sideBar')} ref={animationDivRef}>
            <Search />
            { data.menuItems.map(({ children, title, url, id }) => (
                <div className={classes.linkWrapper} key={id}>
                    {children.length ? (
                        <div
                            className={classNames(classes.linkGroup, {
                                [classes.activeLinkGroup]: id === openedMenuListId
                            })}
                            onClick={() => setOpenedMenuListId(openedMenuListId === id ? null : id)}
                        >   
                            <div className={classes.linkGroupLable}>
                                <p>{title}</p>
                                <ArrowBottmgender color='#FFFFFF' rotate={id === openedMenuListId ? 180 : 0}/>
                            </div>
                            <div className={classes.nestedLinks}>
                                { children.map(({id, url, title}) => (
                                    <Link href={'/'+url} key={id} onClick={closeSidebar}>{title}</Link>  
                                ))}
                            </div>
                        </div>
                    ) : (
                        <Link
                            href={'/'+url}
                            className={classes.onlyLink}
                            onClick={closeSidebar}
                        >
                            {title}
                        </Link>
                    )}
                </div>
            ))}
            <div className={classes.seccondLinkWrapper}>
                {data?.topHeaderItems
                    ?.filter(item => item.title === LINKS_FROM_MENU_TITLES.center && item?.children?.length)
                    ?.flatMap(item => item.children
                        ?.map((childItem, childIndex) => (
                            <Link
                                key={childIndex}
                                href={'/' + childItem.url}
                                className={classes.onlyLink}
                                onClick={closeSidebar}
                            >
                                {childItem.title}
                            </Link>
                        ))
                    )
                }
            </div>
        </div>
    );
};

export { SideBar, Burger }