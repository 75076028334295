export const DislikeIcon: React.FC<{ color?: string; size?: number }> = ({ color = '#99BAC9', size }) => (
    <svg
        width={size ? String(size) : "26"}
        height={size ? String(size) : "26"}
        viewBox={`0 0 26 26`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.31563 12.3217C0.790626 13.0154 0.500001 13.8654 0.500001 14.7498C0.500001 16.1529 1.28437 17.481 2.54688 18.2217C2.87189 18.4123 3.24195 18.5126 3.61875 18.5123L11.1125 18.5123L10.925 22.3529C10.8812 23.281 11.2094 24.1623 11.8469 24.8342C12.1597 25.1653 12.5372 25.4288 12.9559 25.6083C13.3746 25.7879 13.8257 25.8796 14.2813 25.8779C15.9063 25.8779 17.3438 24.7842 17.775 23.2185L20.4594 13.4998L20.4688 13.4998L20.4688 0.124782L5.70938 0.124781C5.42188 0.124781 5.14063 0.181032 4.88125 0.293532C3.39375 0.927906 2.43438 2.38103 2.43438 3.99353C2.43438 4.38728 2.49063 4.77478 2.60313 5.14978C2.07813 5.84353 1.7875 6.69353 1.7875 7.57791C1.7875 7.97165 1.84375 8.35916 1.95625 8.73416C1.43125 9.42791 1.14063 10.2779 1.14063 11.1623C1.14688 11.556 1.20313 11.9467 1.31563 12.3217ZM25.5 12.4998L25.5 1.12478C25.5 0.571657 25.0531 0.124783 24.5 0.124782L22.4688 0.124782L22.4688 13.4998L24.5 13.4998C25.0531 13.4998 25.5 13.0529 25.5 12.4998Z"
            fill={color}
        />
    </svg>
)