import { LabelUI } from '../LabelUI';
import { ChangeEvent, FocusEvent } from 'react';
import classNames from 'classnames';
import classes from './index.module.css';

type HandleChangeType = {
    name?: string;
    event: ChangeEvent<HTMLInputElement>;
    value?: string;
};

interface Iprops {
    name?: string;
    placeholder?: string;
    type?: string;
    label?: string;
    labelIcon?: boolean;
    handleChange?: (props: HandleChangeType) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement, Element>, name?: any) => void;
    value?: string;
    required?: boolean;
    register?: any;
    registerOption?: any;
    error?: string;
    defaultValue?: string;
    classN?: string;
    bg?: string;
    autoComplete?: 'on' | 'off';
    element?: any
};

const InputUI: React.FC<Iprops> = ({
    name,
    type = 'text',
    placeholder,
    labelIcon = false,
    label,
    handleChange,
    onBlur,
    value,
    registerOption,
    register,
    error,
    defaultValue,
    classN ='',
    bg,
    autoComplete = 'off',
    element
}) => {

    const inputProps = {
        name,
        type,
        placeholder,
        autoComplete,
        style: bg ? { backgroundColor: bg } : {},
        ...(defaultValue && { defaultValue }),
        ...(value && { value }),
        ...(registerOption && register && { ...register(name, registerOption) }),
        ...(handleChange && {
            onChange: (event: ChangeEvent<HTMLInputElement>) => handleChange({ name, event, value: event.target.value })
        }),
        ...(onBlur && {
            onBlur: (event) => onBlur(event, name)  
        }),
        id: name
    };

    return (
        <div className={classes.inputList}>
            {label && (
                <LabelUI htmlFor={name} toolti={true} text={label} icon={labelIcon}/>
            )}
            {element ||
                <input
                    className={classNames(classes[classN])}
                    {...inputProps}
                />
            }
            {error && <span className={classes.error}>{error}</span>}
        </div>
    );
};

export { InputUI };
