import { GlobalDatasProps } from "..";
import { WhyUsAdapter } from "@/models/adapter/whyUsAdapter";

export const setWhyUsData = (state: GlobalDatasProps, action: {
    type: 'SET_WHY_US_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = WhyUsAdapter.createWhyUsData(payload.data);
    return {
        ...state,
        whyUs: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setWhyUsError = (state: GlobalDatasProps, action: {
    type: 'SET_WHY_US_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        whyUs: {
            ...state.whyUs,
            error
        }
    };
};

export const setWhyUsLoading = (state: GlobalDatasProps, action: {
    type: 'SET_WHY_US_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        whyUs: {
            ...state.whyUs,
            isLoading
        }
    };
};