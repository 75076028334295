import { GlobalDatasProps } from "..";
import { HistoryAdapter } from "@/models/adapter/historyAdapter";

export const setHistoryData = (state: GlobalDatasProps, action: {
    type: 'SET_HISTORY_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = HistoryAdapter.createHistoryData(payload.data);
    return {
        ...state,
        history: {
            data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setHistoryError = (state: GlobalDatasProps, action: {
    type: 'SET_HISTORY_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        history: {
            ...state.history,
            error
        }
    };
};

export const setHistoryLoading = (state: GlobalDatasProps, action: {
    type: 'SET_HISTORY_LOADING';
    payload: boolean;
}) => {
    const { payload: isLoading } = action;
    return {
        ...state,
        history: {
            ...state.history,
            isLoading
        }
    };
};