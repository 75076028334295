export interface IAuctionData {
    service: boolean;
    title: string;
    offersServiceTitle: string;
    offersServiceDescription: string;
    description: string;
    image: string;
    transportTitle: string;
    transportImage: string,
};

export class AuctionAdapter {
    constructor(
        public service: boolean,
        public title: string,
        public offersServiceTitle: string,
        public offersServiceDescription: string,
        public description: string,
        public image: string,
        public transportTitle: string,
        public transportImage: string,
    ) {};

    public static createAuctionData(data: any): AuctionAdapter {
        return new AuctionAdapter(
            data.service,
            data?.['auction.title']?.value || '',
            data?.['auction.offers_service_title']?.value || '',
            data?.['auction.offers_service_description']?.value || '',
            data?.['auction.description']?.value || '',
            data?.['auction.image']?.page_image?.[0]?.image || '',
            data?.['auction.transport_title']?.value || '',
            data?.['auction.transport_Image']?.page_image?.[0]?.image || '',
        );
    };
};