    import { useEffect, useMemo, useRef, useState } from 'react';
    import { ArrowBottmgender } from '@/public/assets/svges/ArrowBottmgender';
    import classNames from 'classnames';
    import classes from './index.module.css';

    interface Iprops {
        selectedItem?: string;
        setSelectedItem?: (text: string) => void;
        callbackListnerDropdown?: () => void;
        name?: string;
        items: Array<string>;
        setFomrValue?: any;
        getFormValues?: any;
        watch?: any;
        registerOption?: any;
        register?: any;
        trigger?: any;
    };

    const DropdownSelectUI: React.FC<Iprops> = ({
        name,
        items,
        setFomrValue,
        getFormValues,
        registerOption,
        register,
        watch,
        selectedItem,
        setSelectedItem,
        callbackListnerDropdown,
        trigger
    }) => {
        const [isOpen, setIsOpen] = useState<Boolean>(false);
        const dropdownRef = useRef<HTMLDivElement>(null);
        
        const value = watch && watch(name);

        useEffect(() => {
            if(callbackListnerDropdown) {
                callbackListnerDropdown();
            };

            const handleClickOutside = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setIsOpen(false);
                    setFomrValue && setFomrValue(name, '');
                };
            };
            if (isOpen) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            };
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [isOpen]);

        const toggleDropdown = () => setIsOpen(!isOpen);

        const selectList = (text: string) => {
            setFomrValue && setFomrValue(name, text);
            setSelectedItem && setSelectedItem(text);
            trigger && trigger(name)
            toggleDropdown();
        };

        const paintedItems = useMemo(() => {
            if(value === undefined) return items; 
            const findedItem = items.find(item => item === value);
            return findedItem ? items : items.filter(item => item.includes(value))
        }, [value]);

        return (
            <div className={classes.dropdown} ref={dropdownRef}>
                <div onClick={toggleDropdown} className={classes.wrapperDropdownInput}>
                    <input
                        {...(setSelectedItem && { onChange: () => { setSelectedItem('') } })}
                        {...(register && name && registerOption && { ...register(name, registerOption || {}) })}
                        {...(name && {name})}
                        value={(getFormValues && name) ? items.includes(getFormValues(name)) ? getFormValues(name) : (value || '') : selectedItem}
                        className={classNames(classes.dropdownInput, {
                            [classes.dropdownInputIsOpen]: isOpen
                        })}
                        placeholder='Pick up Date'
                        autoComplete='off'
                    />
                    <span className={classNames(classes.wrapperIcon, {
                        [classes.wrapperIconIsOpen]: isOpen
                    })}>
                        <ArrowBottmgender rotate={isOpen ? 180: 0}/>
                    </span>
                </div>
                {Boolean(isOpen && paintedItems.length) && 
                    <ul className={classes.dropdownMenu}>
                        {paintedItems.map((text, index) => (
                            <li key={index} className={classes.dropdownMenuItem} onClick={() => {
                                setTimeout(() => selectList(text))
                            }}>
                                {text}
                            </li>
                        ))}
                    </ul>
                }
                { paintedItems.length === 0 && isOpen && (
                    <ul className={classes.dropdownMenu}>
                        <li className={classNames(classes.dropdownMenuItem, classes.dropdownMenuNoItem)}>No options</li>
                    </ul>
                )}
            </div>
        );
    };

    export { DropdownSelectUI };