import { GlobalDatasProps } from "..";
import { PrivacyAdapter } from "@/models/adapter/privacyAdapter";

export const setPrivacyData = (state: GlobalDatasProps, action: {
    type: 'SET_PRIVACY_DATA';
    payload: any | null;
}) => {
    const { payload } = action;
    const data = PrivacyAdapter.createPrivacyData(payload.data);
    return {
        ...state,
        privacy: {
            data: data,
            error: !payload.action ? true : null,
            isLoading: false
        }
    };
};

export const setPrivacyError = (state: GlobalDatasProps, action: {
    type: 'SET_PRIVACY_ERROR';
    payload: any;
}) => {
    const { payload: { error } } = action;
    return {
        ...state,
        privacy: {
            ...state.privacy,
            error
        }
    };
};